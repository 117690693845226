import "./EditText.scss";
import React from "react";
import { Row, Container, Col, Popover, OverlayTrigger, Button} from 'react-bootstrap';
import {useAppData, AppContextInterface} from "../../../hooks/useAppData";
import { ChromePicker } from 'react-color';

interface Props {

}

export const EditText: React.FC<Props> = (props) => {
    const { fontFamily, setFontfamily, setAccentTextColor, accentTextColor } = useAppData() as AppContextInterface;
    
    const strokePopover = (
        <Popover id="popover-basic2" style={{borderRadius: '10px'}}>
            <Popover.Content style={{height: "100%", padding: 0}}>
                <ChromePicker
                    color={accentTextColor}
                    onChangeComplete={(e : any) => setAccentTextColor(e?.hex)}
                    disableAlpha={true}
                />
            </Popover.Content>
        </Popover>
    );

  return (
    <Container className="text-container">
        <Row>
            <Col xs={12} className="container-col font-col-container" >
                <div className={`font-family-container popins-font ${fontFamily?.includes("Poppins") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Poppins', sans-serif")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Poppins</div>
                </div>
                <div className={`font-family-container bebas-font ${fontFamily?.includes("Bebas") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Bebas Neue', cursive")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Bebas</div>
                </div>
                <div className={`font-family-container dosis-font ${fontFamily?.includes("Dosis") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Dosis', sans-serif")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Dosis</div>
                </div>
                <div className={`font-family-container pacifico-font ${fontFamily?.includes("Pacifico") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Pacifico', cursive")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Pacifico</div>
                </div>
                <div className={`font-family-container roboto-font ${fontFamily?.includes("Roboto") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Roboto', sans-serif")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Roboto</div>
                </div>
                <div className={`font-family-container openSans-font ${fontFamily?.includes("Open Sans") ? "font-container-border" : ""}`} onClick={() => setFontfamily("'Open Sans', sans-serif")}>
                    <div className="edit-button-header">ABC</div>
                    <div className="font-text-container">Open Sans</div>
                </div>
            </Col>
            <Col xs={12} className="container-col fontColor-col-container">
                <div className="font-color-header">Font color:</div>
                <OverlayTrigger trigger="click" rootClose placement="top" overlay={strokePopover}>
                    <Button className="text-color-container"  style={{ backgroundColor: accentTextColor, borderColor: accentTextColor }} />
                </OverlayTrigger>
            </Col>
            
      </Row>
    </Container>
  )
}