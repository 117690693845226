import "./AccountSettings.scss";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Error } from "../Error/Error";
import axiosApi from "../../axiosApi";
import notification from "../Notification";
import 'react-notifications-component/dist/theme.css';
import { AddNewTappy } from "../AddNewTappy/AddNewTappy";
import Button from '../Button';

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[],
    pager_phone: string[],
    work_phone: string[],
    home_phone: string[]
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  pin_active: boolean;
  security_pin: string;
}

interface Pivot {
  profile_id: number,
  social_id: number;
  username: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
  showing: boolean;
}

interface Card {
  id: number;
  profile_id: number;
  enabled: boolean;
  type: string;
  serial_id: number;
  nickname: string;
  created_at: string;
  updated_at: string;
}

interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  socials: Social[] | null;
  cards: Card[] | null;
  contactCard: ContactCard;
}

interface State {
  session: number;
  userData: any;
  profiles: Profile[] | null;
  socials: any;
  selectedProfile: Profile | null;
}

interface Props {
  handleHistory: (url: string) => void;
  state: State;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setLoading: (status: boolean) => void;
}



export const AccountSettings: React.FC<Props> = (props) => {

  const urlParams = new URLSearchParams(window.location.search);
  const serialID: any = urlParams.get('serial');

  const [username, setUsername] = useState(props.state.userData && props.state.userData.username);
  const [email, setEmail] = useState(props.state.userData && props.state.userData.email);
  const [error, setError] = useState({
    active: false,
    message: ""
  })
  const [toggleAddNewTappy, setToggleAddNewTappy] = useState(serialID ? true : false);

  const handleResetPassword = () => {
    props.handleHistory('/updatePassword');
  }

  const validateForm = () => {
    if (email === '') {
      setError({
        active: true,
        message: 'Email cannot be empty!'
      });
      return true;
    } else if (username === '') {
      setError({
        active: true,
        message: 'Username cannot be empty!'
      });
      return true;
    } else {
      setError({
        active: false,
        message: ''
      });
      return false;
    }
  }

  const handleUpdateProfile = async () => {
    let validated = validateForm();
    const accessToken = localStorage.getItem('accessToken');
    if (props.state.userData && props.state.selectedProfile && accessToken && !validated) {

      let data: any = {};
      if (username !== props.state.userData.username) {
        data.username = username;
      }
      if (email !== props.state.userData.email) {
        data.email = email;
      }

      if (Object.keys(data).length === 0) {
        notification({
          message: "User Info already up-to-date",
          type: "success",

        });
      } else {
        try {
          const userResponse = await axiosApi.post(`user/${props.state.userData.id}/update`, data, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });


          if (userResponse.data.error) {
            setError({
              active: true,
              message: userResponse.data.error
            })
          } else {

            props.setState(() => ({
              ...props.state,
              userData: userResponse.data
            }));

            notification({
              message: "Account Successfully Updated",
              type: "success",
              insert: "bottom",
              container: "bottom-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              }
            });
          }
        } catch (err: any) {
          notification({
            message: err.response.data,
            type: "warning",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            }
          });
        }
      }
    }
  }

  return (
    <div className="account-settings">
      {error.active && (
        <Error error={error} />
      )}
      <span className="account-settings__header">Settings</span>
      <Form className="account-settings__account-form">
        <Form.Label
          className="account-settings__account-form__label"
        >Email</Form.Label>
        <Form.Control type="text" value={email} onChange={event => setEmail(event.target.value)} />
        <Form.Label
          className="account-settings__account-form__label"
        >User Name</Form.Label>
        <Form.Control type="text" value={username} onChange={event => setUsername(event.target.value)} />
        <span className="account-settings__account-link text-muted">{process.env.REACT_APP_BASE_URL}/{username}</span>
        <Button onClick={handleUpdateProfile} >Save changes</Button>
      </Form>
      <span className="account-settings__header">Password</span>
      <Form className="account-settings__account-form">
        <Button onClick={handleResetPassword} >Change password</Button>
      </Form>
      <span className="account-settings__header">Manage Products</span>
      <Form className="account-settings__account-form">
        <Button
          className="edit-profile-edit-contact-card__btn tappy__btn"
          onClick={(event) => {
            event.preventDefault();
            setToggleAddNewTappy(true);
          }}
        >
          Manage Tappy Products
        </Button>
      </Form>

      <AddNewTappy onHideModal={() => setToggleAddNewTappy(false)} showModal={toggleAddNewTappy} state={props.state} setState={props.setState} serial={serialID} />
    </div>
  )
}
