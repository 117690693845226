import './ManageSubscription.scss'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from "react-icons/io";

const STEPS_DATA = ['step1', 'step2', 'step3', 'step4']

export const ManageSubscription = forwardRef((props: any, ref) => {

  const [steps, setSteps] = useState(STEPS_DATA[0])
  const [billingDate, setBillingDate] = useState(null)
  const { showModal, hideModal, cancelSubscription, pauseSubscriptionPlan, subscriptionData } = props

  useEffect(() => {
    if (showModal) {
      setSteps(STEPS_DATA[0])
      let dateObject: any = new Date();
      dateObject.setDate(dateObject.getDate() + 30)
      dateObject = dateObject.toLocaleDateString();
      setBillingDate(dateObject)
    }
  }, [showModal])

  useImperativeHandle(ref, () => ({
    pauseSubsPlan: () => {
      setSteps(STEPS_DATA[3])
    }
  }));

  return (
    <Modal
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      className='manage-subscription-modal'
      contentClassName='manage-subscription-content-modal'
      onHide={hideModal}
      centered
    >
      <Modal.Body className='subscription-modal-body'>
        <div className='heading-container'>
          <IoMdClose className="close-subscription-icon" color='#8F9697' size={25} onClick={hideModal} />
          <p className='subscription-text lightColor'>manage subscription</p>
          <p className='subscription-text'></p>
          <p className='subscription-text'></p>
        </div>
        {steps === STEPS_DATA[0] && <>
          <div>
            <div className='displayFlex'>
              <p className='subscription-text right heading'>current plan: </p>
              <p className='subscription-text light heading'>tappy pro</p>
            </div>
            {subscriptionData?.subscription_status_auth_user?.trial_status == 1 &&
              <Button className="subscription-second-btn pause" type='submit' variant="primary" size='lg' onClick={() => setSteps(STEPS_DATA[2])} >Pause subscription - 1 month</Button>
            }
          </div>
          <div onClick={() => setSteps(STEPS_DATA[1])}>
            <p className='subscription-text light underline'>Cancel subscription</p>
          </div>
        </>
        }
        {steps === STEPS_DATA[1] &&
          <>
            <p className='subscription-text mediumFont redColor bottom'>Are you sure you want to cancel?</p>
            <p className='subscription-text mediumFont light bottom'>You will immediately lose all access to tappy pro features.</p>
            <p className='subscription-text mediumFont light bottom'>Your profile will be reset to the free version of tappy.</p>
            <Button className="subscription-second-btn" type='submit' variant="primary" size='lg' onClick={() => setSteps(STEPS_DATA[0])} >Go Back</Button>
            <button
              className='subcription-cancel subscription-text light underline mediumFont'
              onClick={cancelSubscription}
            >
              Yes, cancel subscription</button>
          </>
        }
        {steps === STEPS_DATA[2] &&
          <>
            <p className='subscription-text mediumFont blueColor bottom'>Pause Subscription?</p>
            <p className='subscription-text mediumFont light removeLineHeight'>Billing will be paused for 30 days.</p>
            <p className='subscription-text smallFont veryLight removeLineHeight'>You can continue to enjoy tappy pro features.</p>
            <p className='subscription-text smallFont veryLight removeLineHeight'>You will be billed again on {billingDate}.</p>
            <p className='subscription-text mediumFont light '>Enjoy this free month on us 🎉</p>
            <p className='subscription-text smallFont veryLight'>Offer only valid one time</p>
            <Button className="subscription-second-btn" type='submit' variant="primary" size='lg'
              onClick={pauseSubscriptionPlan} >Confirm</Button>
          </>
        }
        {steps === STEPS_DATA[3] &&
          <>
            <p className='subscription-text mediumFont blueColor bottom'>Subscription Paused</p>
            <p className='subscription-text mediumFont light removeLineHeight'>
              Enjoy this free month on us 🎉
            </p>
            <p className='subscription-text smallFont veryLight removeLineHeight'>You will be billed again on {billingDate}. </p>
            <Button className="subscription-second-btn" type='submit' variant="primary" size='lg' onClick={hideModal} >Return to profile</Button>
          </>
        }
      </Modal.Body >
    </Modal >
  );
})