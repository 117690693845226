import "./LottieLoading.scss";
import React, { useState } from "react";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import lottieJSON from './LottieLoading.json';
import lottieImageLoaderJSON from './LottieImageLoader.json';
import { Helmet } from "react-helmet";

interface State {
  session: number;
  userData: {};
}

interface Props {
  title?: string;
  handleLogout?: () => void;
  handleHistory?: (url: string) => void;
  state?: State;
  showImageLoader?: boolean;
  hideTappy?: boolean;
}

export const LottieLoading: React.FC<Props> = (props) => {

  const { showImageLoader, hideTappy } = props

  return (
    <Player
      lottieRef={instance => {
        //<Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} />
        // the lottie instance is returned in the argument of this prop. set it to your local state
        // console.log(instance);
      }}
      style={showImageLoader === true ? { position: 'unset', width: '100%', height: '100%', padding: 0 } : {}}
      autoplay={true}
      loop={true}
      controls={true}
      // src={showImageLoader === true && hideTappy === true ? lottieImageLoaderJSON : lottieJSON}
      src={lottieJSON}
    ></Player>
  )
}