import React, { useMemo, useState } from "react";
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import "./Dashboard.scss";
import { addTappyBtn, qrCodeBasePath } from "../../utils/StaticData/staticData";
import { AddNewTappy } from "../AddNewTappy/AddNewTappy";
import notification from "../Notification";

interface Props {
  state: any;
  setState: any;
  handleHistory: any;
  setLoading: any;
}

const TappysComponent = ({ item, setToggleAddNewTappy }: any) => {

  const getDarkColor = useMemo(() => {
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10); // Added +5 to skip dark black colors
    }
    return color;
  }, [])

  const getLightColor = useMemo(() => {
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }, [])

  return item?.icon ?
    <div key={item.id} style={{ backgroundColor: `#577df4` }} className="tappy-card-container" onClick={() => setToggleAddNewTappy(true)}>
      {item.icon}
      <p className="common-text title marginTop blackColor">add new</p>
    </div>
    :
    <div key={item.id} style={{ backgroundColor: `${getLightColor}` }} className="tappy-card-container">
      <div style={{ backgroundColor: `${getDarkColor}` }} className={`tappy-card-dot ${item?.type !== 'card' && 'round'}`} />
      <p className="common-text title marginTop blackColor">{item?.nickname}</p>
      <p className="common-text title blackColor">tappy {item?.type}</p>
    </div>
}

export const Dashboard: React.FC<Props> = (props) => {
  const {
    state,
    isActiveSubscription,
    setState,
    setShowStripeModal,
    subscriptionData,
    setShowQR,
    showQR
  } = useAppData() as AppContextInterface;

  const [toggleAddNewTappy, setToggleAddNewTappy] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL

  const cards = state?.selectedProfile?.cards?.length > 0 ? state.selectedProfile.cards : []

  const generateQrCodeLink = useMemo(() => {
    return `${qrCodeBasePath}${state.userData.username}`
  }, [])

  function analyticsPopup() {
    notification({
      message: "Analytics are coming soon...",
      type: "warning",
    });
  }

  return (
    <div className="dashboard-main-container">
      <div className="dashboard-heading-container">
        <p className="common-text subHeading">hello, {state?.userData?.first_name} &#128075;</p>
      </div>
      <div className="commonContainer marginGap">
        <div>
          <p className="common-text heading uppercase">your profile</p>
          <p className="common-text title">{`${baseUrl}/${state?.userData?.username}`}</p>
        </div>
        <div style={{ marginTop: 'auto', marginLeft: 'auto' }} onClick={() => setShowQR(true)}>
          <p className="common-text title uppercase btn">quick share</p>
        </div>
      </div>
      <div className="edit-profile-container">
        <div className="edit-inner-profile-container">
          <div style={{ height: '85%', padding: '10px 0px' }} className="commonContainer columnDirection alignCenter" onClick={() => props?.handleHistory('/editProfile')}>
            <img src="/images/edit-icon.png" style={{ width: '70%', objectFit: 'contain' }} />
            <p className="common-text medium marginTop">Edit Profile</p>
          </div>
          <div style={{ padding: '10px 0px', height: '85%' }} className="commonContainer columnDirection alignCenter marginTop" onClick={analyticsPopup}>
            <img src="/images/analytics-icon.png" style={{ width: '50%', objectFit: 'contain' }} />
            <p className="common-text medium marginTop">Analytics</p>
          </div>
        </div>
        <div style={{ width: '67%', height: '100%' }} className="commonContainer alignCenter">
          <img src={generateQrCodeLink} style={{ width: '95%', height: '95%', objectFit: 'contain' }} />
        </div>
      </div>
      {isActiveSubscription.active === false &&
        <div className="commonContainer marginGap columnDirection">
          <p className="common-text heading">tappy pro</p>
          <p className="common-text title">Analytics, more customization, and more. </p>
          <div onClick={() => setShowStripeModal(true)}>
            <p className="common-text title uppercase btn marginTop">upgrade now</p>
          </div>
        </div>}

      <p className="common-text leftSpace bold">Your Tappy's</p>
      <div className="tappy-container">
        <div className="tappy-inner-container">
          {[...cards, ...addTappyBtn].map((item: any) =>
            <TappysComponent
              item={item}
              setToggleAddNewTappy={setToggleAddNewTappy}
            />
          )}
        </div>
      </div>
      <AddNewTappy onHideModal={() => setToggleAddNewTappy(false)} showModal={toggleAddNewTappy} state={state} setState={setState} serial={''} />
    </div>
  )
}
