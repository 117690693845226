import "./ProfileSettings.scss";
import React, { useEffect, useRef, useState } from "react";
import { Container } from 'react-bootstrap';
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import { CustomLogoData, awsBasePath } from "../../utils/StaticData/staticData";
import notification from "../Notification";
import axiosApi from "../../axiosApi";
interface Props {
}

export const ProfileSettings: React.FC<Props> = () => {
    const {
        accentColor,
        accentTextColor,
        profileBackground,
        buttonShape,
        buttonColor,
        buttonStrokes,
        hideTappyFooter,
        receiveContact,
        uploadOnS3,
        deleteFromS3,
        setHideTappyFooter,
        setShowStripeModal,
        state,
        setState,
        fontFamily,
        setUploadLoader,
        isActiveSubscription } = useAppData() as AppContextInterface;

    const [bannerLogoData, setBannerLogoData] = useState([...CustomLogoData])
    const [selectedItem, setSelectedItem] = useState<any>({ index: null, item: null })
    const [render, setRender] = useState<any>(false)
    const bucketName = process.env.REACT_APP_AWS_BUCKET;
    const inputRef = useRef<any>(null)

    useEffect(() => {
        handleBackgroundLogo()
    }, [state.selectedProfile])

    const handleBackgroundLogo = () => {
        let newData: any = [...bannerLogoData]
        newData[0].imageUrl = state?.selectedProfile?.header_logo || ''
        // newData[1].imageUrl = state?.selectedProfile?.header_logo || ''
        setBannerLogoData(newData)
    }

    const handleInputClick = (item: any, index: any) => {
        if (isActiveSubscription.active) {
            inputRef.current.click()
            setSelectedItem({ index: index, item: item })
        } else {
            setShowStripeModal(true)
        }
    }

    const handleRemove = (item: any) => {

        let currentIndex = item.imageUrl.lastIndexOf("/");
        let currentBannerLogo = item.imageUrl?.slice(currentIndex, item.imageUrl?.length)

        const params: any = {
            Bucket: bucketName,
            Key: `${item.type}${currentBannerLogo}`
        };

        setUploadLoader(true)
        deleteFromS3(params, (status: boolean) => {
            if (!status) {
                notification({
                    message: `${item.type} failed to delete`,
                    type: "danger"
                });
                setUploadLoader(false)
            } else {
                notification({
                    message: `${item.type} deleted successfully`,
                    type: "success"
                });
                updateApi({ [item.type]: null })
            }
        })
    }

    const updateApi = async (bannerLogoData: any) => {

        try {
            const profileData = {
                accent_color: accentColor,
                accent_text_color: accentTextColor,
                background_color: profileBackground,
                backgound_image: '',
                background_video: '',
                button_shape: buttonShape,
                button_color: buttonColor,
                button_stroke: buttonStrokes,
                header_logo: typeof bannerLogoData['logo'] !== 'undefined' ? bannerLogoData['logo'] : (state?.selectedProfile?.header_logo || null),
                banner_image: typeof bannerLogoData['banner'] !== 'undefined' ? bannerLogoData['banner'] : (state?.selectedProfile?.banner_image || null),
                receive_contact: receiveContact,
                hide_tappy_footer: hideTappyFooter,
                font_family: fontFamily,
            };
            const response = await axiosApi.post(`user/${state.session}/profile/${state.selectedProfile.id}/update-profile-design`, profileData);
            if (response.status === 200) {
                setState(() => ({
                    ...state,
                    selectedProfile: { ...state.selectedProfile, ...profileData },
                }));
                notification({
                    message: 'Branding updated successfully',
                    type: "success"
                });
                setUploadLoader(false)
            } else {
                setUploadLoader(false)
                notification({
                    message: 'Something went wrong',
                    type: "danger"
                });
            }
        } catch (e: any) {
            setUploadLoader(false)
            notification({
                message: e?.message || e?.response?.data,
                type: "warning"
            });
        }
    }

    const handleChange = async (e: any) => {
        const imageFiles: any = e.target.files
        const { item }: any = selectedItem
        if (!imageFiles?.length) {
            setSelectedItem({ index: null, item: null })
            return false
        }
        const fileName = `${state.selectedProfile.user_id.toString()}_${new Date().getTime()}_${item.type}${imageFiles[0].name.split(".").pop()}`;

        const params = {
            Bucket: bucketName ? bucketName : "",
            Key: `${item.type}/${fileName}`,
            Body: imageFiles[0],
        };
        setUploadLoader(true)

        const dataKey = item.type === 'banner' ? 'banner_image' : 'header_logo'

        if (state?.selectedProfile?.[dataKey]?.length > 0) {
            const imageUrl = state?.selectedProfile?.[dataKey]
            let currentIndex = imageUrl.lastIndexOf("/");
            let currentBannerLogo = imageUrl?.slice(currentIndex, imageUrl?.length)

            const deleteParam: any = {
                Bucket: bucketName,
                Key: `${item.type}${currentBannerLogo}`
            };
            await deleteFromS3(deleteParam, (status: boolean) => {
                console.log('status:::', status)
            })
        }
        uploadOnS3(params, (status: boolean, progress: number) => {
            if (status) {
                const imageParams = {
                    [item.type]: `${awsBasePath}${item.type}/${fileName}`
                }
                updateApi(imageParams)
                notification({
                    message: `${item.type} uploaded successfully`,
                    type: "success",
                });
            } else if (!status && typeof progress === 'undefined') {
                notification({
                    message: `${item.type} upload failed`,
                    type: "danger",
                });
                setUploadLoader(false)
            }

        })
        setSelectedItem({ index: null, item: null })
    }

    return (
        <Container className="custom-banner-main-container">
            <>
                {!isActiveSubscription.active && <div className="pro-feature-container">
                    <p className="banner-text center">unlock access to custom branding features and more in tappy pro</p>
                    <div onClick={() => setShowStripeModal(true)}>
                        <p className="banner-text medium upload-btn top">get tappy pro</p>
                    </div>
                </div>}
                <input
                    id="file"
                    type="file"
                    ref={inputRef}
                    key={render}
                    disabled={!isActiveSubscription.active}
                    style={{ display: 'none' }}
                    accept={`image/${selectedItem.item?.type === 'banner' ? '*' : 'png'}`}
                    onChange={(e) => handleChange(e)}
                />
                {bannerLogoData.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <p className="banner-text bold top">{item.heading}</p>
                        <p className="banner-text light small">{item.subHeading}</p>
                        {item.imageUrl.length <= 0 ?
                            <div className="upload-btn-container" style={{ height: item.height }}>
                                <div onClick={() => handleInputClick(item, index)}>
                                    <p className={`banner-text medium upload-btn ${!isActiveSubscription.active && 'active'}`} >upload</p>
                                </div>
                            </div>
                            :
                            <>
                                <div className="upload-btn-container transparent" style={{ height: item.height }}>
                                    <img src={item.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                                <div className="edit-buttons">
                                    <div onClick={() => handleInputClick(item, index)}>
                                        <p className="banner-text medium upload-btn">Change</p>
                                    </div>
                                    <div onClick={() => handleRemove(item)}>
                                        <p className="banner-text medium upload-btn red">Remove</p>
                                    </div>
                                </div>
                            </>
                        }
                    </React.Fragment>
                )}
            </>
            <div className="container-col profile-setting-col-container">
                <div className="toggle-container">
                    <span className="switch-toggle-lable-header" style={{ textAlign: "center" }}>Hide tappy bottom button</span>
                    <div className="toggleActive">
                        <input type="checkbox" id="toggleActive1" className="checkbox"
                            checked={hideTappyFooter}
                            onChange={() => {
                                if (isActiveSubscription.active) {
                                    setHideTappyFooter(!hideTappyFooter)
                                } else {
                                    setShowStripeModal(true)
                                }
                            }}
                        />
                        <label htmlFor="toggleActive1" className="switch"></label>
                    </div>

                </div>
            </div>
        </Container>
    )
}