import "./PasswordReset.scss";
import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosApi from "../../axiosApi";
import { Error } from "../Error/Error";
import notification from "../Notification";
import 'react-notifications-component/dist/theme.css';

import Button from '../Button';
import { AppContextInterface, useAppData } from "../../hooks/useAppData";

interface Props {
  handleHistory: (url: string) => void;
}

export const PasswordReset: React.FC<Props> = (props) => {

  const {
    setUploadLoader,
    uploadLoader
  } = useAppData() as AppContextInterface;

  const [username, setUsername] = useState("");
  const [error, setError] = useState({
    active: false,
    message: ""
  })

  /**
   * Keyboard on enter key pressed event
   * Only one per page recommended
   */
  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void; }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //console.log("Enter key was pressed.");
        event.preventDefault();
        document.getElementById('forgetBtn')?.click();
        //because calling handleForgetpassword gives 422 error
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleForgetPassword = async () => {

    setUploadLoader(true)
    try {
      const forgetResponse = await axiosApi.post(`forgot-password`, { email: username });

      if (forgetResponse.data.status) {
        notification({
          message: "Email verification has been sent!",
          type: "success",
        });
        setError({
          active: false,
          message: '',
        });
        props.handleHistory('login');
      } else {
        setError({
          active: true,
          message: forgetResponse?.data?.errors?.email || "Something went wrong!",
        })
      }
      setUploadLoader(false)
    }
    catch (err: any) {
      setUploadLoader(false)
      notification({
        message: err?.data?.errors?.email || "Something went wrong!",
        type: "danger",
      });
    }
  }


  return (
    <>
      {uploadLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
      <div className="reset-password">
        {error.active && (
          <Error error={error} />
        )}
        <Form className="reset-password__form">
          <Form.Label className="reset-password__label">
            Password Reset 🔑
          </Form.Label>
          <span className="reset-password__description">Enter your Tappy username, or the email address that you used to register. We'll send you an email with your username and a link to reset your password.</span>
          <span className="reset-password__description">Already have a token? <a href="" onClick={() => props.handleHistory('updatePassword')}>Update Here</a>.</span>
          <span className="reset-password__username">username or email</span>
          <Form.Control
            type="text"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          <div className="reset-password__links">
            <Button
              onClick={(event) => {
                event.preventDefault();
                handleForgetPassword();
              }}
              id="forgetBtn"
            >Send Email</Button>
            <Link to="/updatePassword">
              <span className="forgot-password">Reset Password</span>
            </Link>
          </div>
        </Form>


        <Button
          onClick={() => {
            props.handleHistory('/login');
          }}
          className="reset-password__back">
          <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4561 4.31036L2.00006 4.31037M2.00006 4.31037L4.76006 7.62073M2.00006 4.31037L4.76006 1" stroke="#40538D" strokeWidth="2" />
          </svg>
          Go back to home
        </Button>

      </div>
    </>
  );
}