import "./AddContactCard.scss";
import React, { useState } from "react";
import {
  Form,
  Modal
} from "react-bootstrap";
import axiosApi from "../../axiosApi";
import notification from "../Notification";
import Button from '../Button';

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[],
    pager_phone: string[],
    work_phone: string[],
    home_phone: string[]
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  pin_active: boolean;
  security_pin: string;
}

interface Pivot {
  profile_id: number,
  social_id: number;
  username: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
}

interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  socials: Social[] | null;
  pin_active: boolean;
  contactCard: ContactCard;
}

interface State {
  session: number;
  userData: {};
  selectedProfile: Profile | null;
}

interface Props {
  toggleModal: boolean;
  onHideAddContactCard: () => void;
  state: State;
  setState: React.Dispatch<React.SetStateAction<any>>;
  profile: Profile | null;
  setName: (name: string) => void;
  setContact: (content: any) => void;
}


export const AddContactCard: React.FC<Props> = (props) => {
  


  const [contactCardCode, setContactCardCode] = useState("");

  const handleAddContact = async () => {
    const data = {
      security_pin: contactCardCode
    }

    if (props.profile) {
      try {
        const response = await axiosApi.post(`user/${props.profile.user_id}/profile/${props.profile.id}/contact/verify`, data);

        if (response.data.pin_verified === "true") {
          props.onHideAddContactCard();

          const contactData = response.data.contact;
          let selectedProfile = {...props.profile};
          selectedProfile.contactCard = contactData;
      
          props.setState(() => ({
            ...props.state,
            selectedProfile: selectedProfile
          }))
          
          // Populates the data onto a vCard (VCF file)

          var vCardsJS = require('vcards-js');
          //create a new vCard
          var vCard = vCardsJS();
          vCard.isOrganization = true; // For iPhone Contact Book

          //set properties
          vCard.firstName = contactData.first_name && contactData.first_name.toString();
          vCard.lastName = contactData.last_name && contactData.last_name.toString();
          vCard.organization = contactData.company && contactData.company.toString();
          if (props.state.selectedProfile && props.state.selectedProfile.photo) {
            if (props.state.selectedProfile.photo !== "") {
              vCard.photo.attachFromUrl(props.state.selectedProfile.photo);
            } else {
              vCard.photo.embedFromFile('/images/tappy-avatar.png');
            }
          }

          vCard.email = selectedProfile.contactCard.email;

          vCard.workAddress.label = 'Full Address';
          vCard.workAddress.street = selectedProfile.contactCard.address;

          vCard.url = selectedProfile.contactCard.website;

          if (contactData.phones) {
            vCard.workPhone = selectedProfile.contactCard.phones.work_phone;
            vCard.cellPhone = selectedProfile.contactCard.phones.cell_phone;
            vCard.homePhone = selectedProfile.contactCard.phones.home_phone;
            vCard.pagerPhone = selectedProfile.contactCard.phones.pager_phone;
          }
          if (contactData.birthday) {
            vCard.birthday = new Date(contactData.birthday);
          }
          vCard.note = contactData.notes && contactData.notes.toString();
          
          props.setContact(vCard.getFormattedString());
          props.setName(vCard.firstName + '.vcf');

          document.getElementById('vcard-form')?.click();

          // Reset to security
          props.setContact('contents');
          props.setName('name');

        } else {
          notification({
            message: "Invliad Pin",
            type: "warning",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2500,
            }
          });
        }
      } catch (err) {
        notification({
          message: "Network Error",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2500,
          }
        });
      }
    }
  }

  return (

  <Modal 
    show={props.toggleModal} 
    onHide={() => props.onHideAddContactCard()}
    className="add-contact-card-modal">
    <Modal.Header closeButton>
    </Modal.Header>
    <div className="add-contact-card__modal">
      <Form className="add-contact-card__modal__form">
        <Form.Label
        className="contact-card-secure-header">
          {props.profile ? props.profile.name : null}'s contact card is secured</Form.Label>
        <span className="contact-card-digit-prompt">enter the security pin to add this contact:</span>
        <Form.Control 
          type="email" 
          value={contactCardCode}
          onChange={(event) => {
            setContactCardCode(event.target.value);
          }}/>
        <Button
          onClick={(event) => {
            event.preventDefault();
            handleAddContact();
          }}
        >Enter</Button>
      </Form>
    </div>
  </Modal>
  );
}
