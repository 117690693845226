import ReactGA from "react-ga4";

type EventProps = {
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
  transport?: any,
}


export const googleAnalyticsEvent = ({ category, action, label, value, nonInteraction, transport }: EventProps) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
    nonInteraction: nonInteraction, // optional, true/false
    transport: transport, // optional, beacon/xhr/image
  })
}

export const googleAnalyticsCustomEvent = ({ action, key, value }: any) => {
  ReactGA.event(action, { [key]: value })
}

export const shortenString = (stringName: string, length = 10) => {
  return stringName?.length > length ? stringName.slice(0, length) + '...' : stringName
}

export const getRouteName = (routeName: string) => {
  if (routeName?.length > 0) {
    const lastIndex = routeName.lastIndexOf("/")
    let newRouteName: any = routeName.slice(lastIndex + 1)
    newRouteName = newRouteName.split(/(?=[A-Z])/)
    newRouteName = newRouteName.join(" ")
    return newRouteName.charAt(0).toUpperCase() + newRouteName.slice(1)
  }
  return false
}

googleAnalyticsEvent.defaultProps = {
  value: 1,
  nonInteraction: false,
  transport: "beacon" // as it will be used as default in future.
}
