import axios from 'axios';

const axiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_PROXY_DEVELOPMENT}/api/`,
});

export const setAuthHeader = token => {
  axiosApi.defaults.headers.common.Authorization =
      `Bearer ${token}` || `${localStorage.getItem('accessToken')}`;
};

axiosApi.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'accessToken',
)}`;

//if a 401 happens, the user will be logged out
axiosApi.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error && error.response && error.response.status === 401) {
    localStorage.removeItem('accessToken');
  }
  return Promise.reject(error);
});

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(localStorage.getItem('accessToken'));

export default axiosApi;