import './StripeAdvertisement.scss'
import { FaCheckCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { modalSteps, proCustomFeatures } from '../../../utils/StaticData/staticData';
import { IoClose } from "react-icons/io5";

export const StripeAdvertisementComp = (props: any) => {

  const handleNavigation = () => {
    if (props?.steps?.type) {
      props.setSteps({ type: modalSteps[1], param: '' })
    } else {
      props.handleHistory('/stripeSubscribption')
    }
  }

  return (
    <div className={`stripe ${props?.steps?.type ? '' : 'stripe-main'}`}>
      {props?.steps?.type && <IoClose className="closed-icon" onClick={() => props?.hideModal()} />}
      <div className='stripe-container'>
        <p className='stripe-container_tag'>current plan: tappy free</p>
        <p className='stripe-container_head'>unlock more features with
          <p className='stripe-container_head-bold'>tappy pro</p>
        </p>
        <div className='stripe-label'>
          <p className='stripe-container_tag'>watch the tappy pro demo video </p>
        </div>
      </div>
      {proCustomFeatures.map((item, id) => {
        return <div className='stripe-check'>
          <div key={id} className='stripe-check-box' >
            <div>
              <FaCheckCircle className='stripe-check-box_circle' />
            </div>
            <div>
              <p className={`stripe-check-box_text ${item.id === 1 ? 'first' : 'second'}`}>{item?.name}</p>
              {item.id === 1 &&
                <p className='stripe_text'>
                  Automatically receive each other's contact info via a text message
                </p>
              }
            </div>
          </div>
        </div>
      })}
      <p className='stripe-container_tag'>and even more features coming soon!</p>
      <div className='stripe-container'>
        <p className='pricelineTag'>starting at $6.99/month</p>
        <Button className='try-btn' variant="primary" size='lg' onClick={handleNavigation} >try free for 14 days</Button>
      </div>
    </div>
  );
}