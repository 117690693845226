import { Store } from 'react-notifications-component';

const config = {
    insert: "bottom",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
    },
}
const addNotification = (options: any) => {
    Store.addNotification({
        ...config,
        ...options
    });
}
export default addNotification