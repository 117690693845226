import './StripeSelectSubscribption.scss'
import React from "react";
import { StripeSelectSubscribptionComp } from './StripeSelectSubscribptionComp';
import { useLocation } from 'react-router';

interface Props {
  handleHistory: (url: string) => void;
}

export const StripeSelectSubscribption: React.FC<Props> = (props) => {
  const location : any = useLocation();

  return (
    <StripeSelectSubscribptionComp {...props} params={location?.state?.params}/>
  )
}