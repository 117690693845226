import "./PasswordUpdate.scss";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import axiosApi from "../../axiosApi";
import { Error } from "../Error/Error";
import notification from "../Notification";
import 'react-notifications-component/dist/theme.css';
import Button from '../Button';
import { AppContextInterface, useAppData } from "../../hooks/useAppData";

interface Props {
    handleHistory: (url: string) => void;
}

export const PasswordUpdate: React.FC<Props> = (props) => {

    const {
        setUploadLoader,
        uploadLoader
    } = useAppData() as AppContextInterface;

    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState({
        active: false,
        message: ""
    });
    const [hidePassword, setHidePassword] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const serialID: any = urlParams.get('token');
        //console.log(serialID);

        if (serialID) {
            setToken(serialID);
        }
    }, [])

    const handleForgetPassword = async () => {
        if (!verifyInput()) {
            return;
        }
        setUploadLoader(true)
        try {
            const data = { token: token, email: email, password: password, password_confirmation: confirmPassword };

            const forgetResponse = await axiosApi.post(`reset-password`, data);

            if (forgetResponse.data.status) {
                notification({
                    message: "Password Changed Successfully!",
                    type: "success",
                });
                setError({
                    active: false,
                    message: '',
                });
                props.handleHistory('login');
            } else {
                setError({
                    active: true,
                    message: forgetResponse.data.errors.email,
                })
            }
            setUploadLoader(false)
        } catch (err: any) {
            notification({
                message: err?.response?.data?.[0] || "Something went wrong!",
                type: "danger",
            });
            setUploadLoader(false)
        }

    }

    const verifyInput = () => {
        if (email === "") {
            setError({
                active: true,
                message: "Email Cannot be Empty.",
            })
            return false;
        } else if (token === "") {
            setError({
                active: true,
                message: "You need a token to change your password.",
            })
            return false;
        } else if (password !== confirmPassword) {
            setError({
                active: true,
                message: "Passwords do not Match.",
            })
            return false;
        } else if (password?.length < 8) {
            setError({
                active: true,
                message: "The password must be at least 8 characters.",
            })
            return false;
        }
        return true;
    }

    const togglePassword = () => {
        setHidePassword(!hidePassword);
    }

    return (
        <>
            {uploadLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
            <div className="reset-password">
                {error.active && (
                    <Error error={error} />
                )}
                <Form className="reset-password__form">
                    <Form.Label className="reset-password__label">
                        Set New Password 🔑
                    </Form.Label>
                    <span className="reset-password__description">To reset your password, please copy and paste the token in your email.</span>
                    <span className="reset-password__description">Don't have a token? <a href="" onClick={() => props.handleHistory('resetPassword')}>Get it Here</a>.</span>
                    <span className="reset-password__username">Email</span>
                    <Form.Control
                        type="text"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <span className="reset-password__username">Token</span>
                    <Form.Control
                        type="text"
                        value={token}
                        onChange={(event) => {
                            setToken(event.target.value);
                        }}
                    />
                    <span className="reset-password__username">Password</span>
                    <InputGroup>
                        <Form.Control
                            type={hidePassword ? "password" : "text"}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)} />
                        <InputGroup.Append onClick={() => { togglePassword() }}>
                            {hidePassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown" />}
                        </InputGroup.Append>
                    </InputGroup>

                    <span className="reset-password__username">Confirm Password</span>
                    <InputGroup>
                        <Form.Control
                            type={hidePassword ? "password" : "text"}
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)} />
                        <InputGroup.Append onClick={() => { togglePassword() }}>
                            {hidePassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown" />}
                        </InputGroup.Append>
                    </InputGroup>

                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            handleForgetPassword();
                        }}
                    >Update Password</Button>
                </Form>


                <Button
                    onClick={() => {
                        props.handleHistory('/login');
                    }}
                    className="reset-password__back">
                    <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4561 4.31036L2.00006 4.31037M2.00006 4.31037L4.76006 7.62073M2.00006 4.31037L4.76006 1" stroke="#40538D" strokeWidth="2" />
                    </svg>
                    Go back to home
                </Button>

            </div>
        </>
    );
}