import { FaLinkedin, FaTiktok, FaInstagram } from "react-icons/fa";
import { IoCard } from "react-icons/io5";
import { CgAdd } from "react-icons/cg";

export const imagesFolderName: string = 'background-media-image';
export const videosFolderName: string = 'background-media-video';
export const thumbnailFolderName: string = 'thumbnail';
export const bannerFolderName: string = 'banners';
export const headerLogoFolderName: string = 'header-logo';

let currentDate: any = new Date()
currentDate = currentDate.setDate(currentDate.getDate() + 14)
let newDate: any = new Date(currentDate)
newDate = newDate.toLocaleDateString()

export const stripeStatus = ['trialing', 'active']

export const awsBasePath = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`

export const qrCodeBasePath = `https://api.qrserver.com/v1/create-qr-code/?size=650x650&bgcolor=2c2c2c&color=ababab&data=https://${process.env.REACT_APP_BASE_URL}/`

export const modalSteps = ['modalStep1', 'modalStep2', 'modalStep3']

export const addTappyBtn = [{ id: 1, icon: <CgAdd size={80} /> }]

export const availableThemes = [
  {
    id: 1,
    key: "dark",
    title: "Original"
  },
  {
    id: 2,
    key: "theme-2",
    title: "Center"
  },
];

export const maxMediaSize: any = {
  image: 10000000,
  video: 30000000
}

export const CustomLogoData = [
  // {
  //     id: 1,
  //     heading: 'Banner Image',
  //     subHeading: 'Recommended size: 800 x 300px',
  //     height: '140px',
  //     type: 'banner',
  //     imageUrl: ''
  // },
  {
    id: 2,
    heading: 'Header logo',
    subHeading: 'Recommended size: 160 x 60px',
    height: '97px',
    type: 'logo',
    imageUrl: ''
  },
]

export const custombackgroundImages: Array<any> = [
  {
    id: 9876,
    url: ''
  },
  {
    id: 98765,
    url: 'Antelope.jpg'
  },
  {
    id: 987654,
    url: 'Bridge.jpeg'
  },
  {
    id: 32132,
    url: 'Stardust.jpeg'
  },
]

export const custombackgroundVideos: Array<any> = [
  {
    id: 11111,
    url: ''
  },
  {
    id: 222221,
    url: 'deep_blue.mp4'
  },
  {
    id: 222222,
    url: 'deep_red.mp4'
  },
  {
    id: 323222,
    url: 'blue_slime.mp4'
  },
  {
    id: 565465,
    url: '1.wooden_waves_final.mp4'
  },
  {
    id: 65456,
    url: '10.water_final.mp4'
  },
  {
    id: 78565,
    url: '11.waves_final.mp4'
  },
  {
    id: 956,
    url: '3.splash_2bw_final.mp4'
  },
  {
    id: 1454560,
    url: '4.linen_final.mp4'
  },
  {
    id: 14561,
    url: '5.pixels_final.mp4'
  },
  {
    id: 156462,
    url: '6.tape.mp4'
  },
  {
    id: 1546543,
    url: '7.Jellyfish_final.mp4'
  },
  {
    id: 15464,
    url: '8.Smoke_final.mp4'
  },
  {
    id: 1546455,
    url: '9.water_2_final.mp4'
  },
  {
    id: 16456456,
    url: 'Abstract_final.mov'
  },
]

export const headerSocialIcons: any = [
  {
    icon: <FaLinkedin size={24} />,
    url: 'https://www.linkedin.com/company/tappycard/'
  },
  {
    icon: <FaTiktok size={21} />,
    url: 'https://www.tiktok.com/@tappy'
  },
  {
    icon: <FaInstagram size={24} />,
    url: 'https://www.instagram.com/tappycard'
  },
]

export const proFeatures = [
  {
    id: 1,
    label: "You won't be billed today",
    firstTime: true
  },
  {
    id: 4,
    label: "You will be billed from today",
    secondTime: true
  },
  {
    id: 2,
    label: `Cancel any time before ${newDate}`,
    firstTime: true
  },
  {
    id: 3,
    label: 'Secure payment via Stripe'
  }
]

export const paymentMethods = [
  {
    id: 1,
    name: 'Card',
    icon: <IoCard style={{ width: '100%', height: '100%' }} />
  },
  // {
  //   id: 2,
  //   name: 'Apple Pay',
  //   icon: <FaCcApplePay style={{ width: '100%', height: '100%' }} />
  // },
]

export const trialPeriods = [
  {
    id: 1,
    name: 'Monthly',
    price: '6.99',
    text: 'Cancel anytime'
  },
  {
    id: 2,
    name: 'Annual',
    price: '60',
    text: '(save 28%)'
  },
]

export const proCustomFeatures = [
  {
    id: 1,
    name: 'Spark sms',
  },
  {
    id: 2,
    name: 'Custom backgrounds',
  },
  {
    id: 3,
    name: 'Add company logo',
  },
  {
    id: 4,
    name: 'Remove tappy branding',
  },
  {
    id: 6,
    name: 'Verified profile',
  }
]