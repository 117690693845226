import React from "react";
import { AddPaymentMethodComp } from './AddPaymentMethodComp';
import { useLocation } from 'react-router-dom';

interface Props {
  handleHistory: (url: string) => void;
  goBack: () => void;
}

export const AddPaymentMethod: React.FC<Props> = (props) => {
  const location : any = useLocation();
  return (
    <AddPaymentMethodComp {...props} params={location?.state?.params} />
  )
}