import "./EditButtons.scss";
import React, { useState, useRef } from "react";
import { Row, Container, Col, Popover, OverlayTrigger, Button} from 'react-bootstrap';
import {useAppData, AppContextInterface} from "../../../hooks/useAppData";
import { AlphaPicker, SketchPicker, ChromePicker } from 'react-color';

interface Props {

}

export const EditButtons: React.FC<Props> = (props) => {
    const { 
        buttonShape, 
        setButtonShape, 
        setButtonColor, 
        buttonColor, 
        buttonStrokes, 
        setButtonStrokes
    } = useAppData() as AppContextInterface;

    const defaultColor = 'rgba(0,0,0,1)' 

    const popover = (
        <Popover id="popover-basic" style={{borderRadius: '10px'}}>
            <Popover.Content style={{height: "100px"}}>
                <div className="alpha-picker-container">
                    <div className="">Select Opacity</div>
                    <div>
                        <div className="opacity-text">{buttonColor?.a}</div>
                        <AlphaPicker 
                            color={buttonColor || defaultColor}
                            onChange={(updatedColor) =>
                                setButtonColor((prevColor: { a: any; }) => {
                                const { r, g, b, a } = updatedColor.rgb;
                                return {
                                    r,
                                    g,
                                    b,
                                    a: a !== undefined ? a : prevColor.a,
                                };
                                })
                            }
                        /> 
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );

    const colorPopover = (
        <Popover id="popover-basic1" style={{borderRadius: '10px'}}>
            <Popover.Content style={{height: "100%", padding: 0}}>
            <SketchPicker
                color={ buttonColor || defaultColor }
                onChangeComplete={(updatedColor) =>
                    setButtonColor((prevColor: { a: any; }) => {
                    const { r, g, b, a } = updatedColor.rgb;
                    return {
                        r,
                        g,
                        b,
                        a: a !== undefined ? a : prevColor.a,
                    };
                    })
                }
            />
            </Popover.Content>
        </Popover>
    );

    const strokePopover = (
        <Popover id="popover-basic2" style={{borderRadius: '10px'}}>
            <Popover.Content style={{height: "100%", padding: 0}}>
                <ChromePicker
                    color={buttonStrokes || defaultColor}
                    onChangeComplete={(e : any) => setButtonStrokes(e?.hex)}
                    disableAlpha={true}
                />
            </Popover.Content>
        </Popover>
    );

  return (
    <Container className="button-container">
        <Row>
            <Col xs={4} className="container-col">
                <div className={`sharp-button button-shape-container ${buttonShape === "sharp" ? "button-design-radius" : ''}`}  onClick={() => setButtonShape("sharp")} />
                <div className="button-text">Sharp</div>
            </Col>
            <Col xs={4} className="container-col">
                <div className={`rounded-button button-shape-container ${buttonShape === "rounded" ? "button-design-radius" : ''}`} onClick={() => {setButtonShape("rounded")}} />
                <div className="button-text">Rounded</div>
            </Col>
            <Col xs={4} className="container-col">
                <div className={`circular-button button-shape-container ${buttonShape === "circular" ? "button-design-radius" : ''}`} onClick={() => {setButtonShape("circular")}} />
                <div className="button-text">Circular</div>
            </Col>
            <Col className="container-col">
                <div className="edit-button-header">Color</div>
                <OverlayTrigger trigger="click" rootClose placement="top" overlay={colorPopover}>
                    <Button className="color-container" style={{backgroundColor: `rgba(${buttonColor?.r}, ${buttonColor?.g}, ${buttonColor?.b}, ${buttonColor?.a})`}} />
                </OverlayTrigger>
            </Col>
            <Col className="container-col" style={{position: "relative"}}>
                <div className="edit-button-header">Opacity</div>
                <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover}>
                    <Button className="opacity-container" />
                </OverlayTrigger>
            </Col>
            <Col className="container-col">
                <div className="edit-button-header">Stroke</div>
                <OverlayTrigger trigger="click" rootClose placement="top" overlay={strokePopover}>
                    <Button className="color-container"  style={{ backgroundColor: buttonStrokes }} />
                </OverlayTrigger>
            </Col>
      </Row>
    </Container>
  )
}