import "./AddInformationField.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import {AppContextInterface, useAppData} from "../../hooks/useAppData";
import {Helmet} from "react-helmet";

interface Props {
  showModal: boolean;
  onHideModal: () => void;
  addCellNumber: (num: string) => void;
  addWorkNumber: (numer: string) => void;
  addHomeNumber: (numer: string) => void;
  toggleFirstName: () => void;
  toggleLastName: () => void;
  toggleCompany: () => void;
  toggleCellNumber: () => void;
  toggleWorkNumber: () => void;
  toggleHomeNumber: () => void;
  toggleEmail: () => void;
  toggleWebsite: () => void;
  toggleAddress: () => void;
  toggleBirthday: () => void;
  toggleNotes: () => void;
}

export const AddInformationField: React.FC<Props> = (props) => {
  const appData = useAppData() as AppContextInterface;
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.add-information-field .modal-content {border: 3px  solid ${appData.accentColor}}`;
  return (
    <>
      <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
      <Modal
    show={props.showModal}
    onHide={() => props.onHideModal()}
    className="add-information-field">
      <Modal.Header closeButton>
      </Modal.Header>
      <div className="add-information-field-options">
        <span
        onClick={() => {
          props.toggleFirstName();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        First name</span>
        <span
        onClick={() => {
          props.toggleLastName();
        }}>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg> 
        Last name</span>
        <span
        onClick={() => {
          props.toggleCompany();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Company</span>
        <span
        onClick={() => {
          props.addCellNumber('');
          props.toggleCellNumber();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Cell Phone number</span>
        <span
        onClick={() => {
          props.addWorkNumber('');
          props.toggleWorkNumber();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Work Phone number</span>
        <span
        onClick={() => {
          props.addHomeNumber('');
          props.toggleHomeNumber();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Home Phone number</span>
        <span
        onClick={() => {
          props.toggleEmail();
        }}>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>  
        Email</span>
        <span
        onClick={() => {
          props.toggleWebsite();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>  
        Website</span>
        <span
        onClick={() => {
          props.toggleAddress();
        }}>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Address</span>
        <span
        onClick={() => {
          props.toggleBirthday();
        }}
        >
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Birthday</span>
        <span
        onClick={() => {
          props.toggleNotes();
        }}>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.5 0.8125C9.62793 0.8125 0.8125 9.62793 0.8125 20.5C0.8125 31.3721 9.62793 40.1875 20.5 40.1875C31.3721 40.1875 40.1875 31.3721 40.1875 20.5C40.1875 9.62793 31.3721 0.8125 20.5 0.8125ZM28.9375 21.5547C28.9375 21.748 28.7793 21.9062 28.5859 21.9062H21.9062V28.5859C21.9062 28.7793 21.748 28.9375 21.5547 28.9375H19.4453C19.252 28.9375 19.0938 28.7793 19.0938 28.5859V21.9062H12.4141C12.2207 21.9062 12.0625 21.748 12.0625 21.5547V19.4453C12.0625 19.252 12.2207 19.0938 12.4141 19.0938H19.0938V12.4141C19.0938 12.2207 19.252 12.0625 19.4453 12.0625H21.5547C21.748 12.0625 21.9062 12.2207 21.9062 12.4141V19.0938H28.5859C28.7793 19.0938 28.9375 19.252 28.9375 19.4453V21.5547Z" fill={appData.accentColor}/>
        </svg>
        Notes</span>
      </div>
    </Modal>
    </>
  
  );
}

