import './App.scss';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import { Header } from "../Header/Header";
import { Default } from "../Default/Default";
import { Login } from "../Login/Login";
import { Register } from "../Register/Register";
import { EditProfile } from "../EditProfile/EditProfile";
import { ProfilePage } from "../Profile/Profile";
import { PasswordReset } from "../PasswordReset/PasswordReset";
import { PasswordUpdate } from "../PasswordUpdate/PasswordUpdate";
import { TappyActivation } from "../TappyActivation/TappyActivation";
import { AccountSettings } from "../AccountSettings/AccountSettings";
import { LottieLoading } from "../LottieLoading/LottieLoading";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import usePageTracking from '../../hooks/usePageTracking';
import { StripeAdvertisement } from '../Stripe/StripeAdvertisement/StripeAdvertisement';
import { StripeSelectSubscribption } from '../Stripe/StripeSelectSubscribption/StripeSelectSubscribption';
import { AddPaymentMethod } from '../Stripe/AddPaymentMethod/AddPaymentMethod';
import { ActiveSubscription } from '../Stripe/ActiveSubscription/ActiveSubscription';
import { Dashboard } from '../Dashboard/Dashboard';
import { googleAnalyticsCustomEvent } from '../../utils/google-analytics';
import { getCurrentDateTime } from '../../utils/global/global';

export const App: React.FC = () => {

  const {
    state,
    setState,
    checkUserSession,
    handleHistory,
    goBack,
    handleLogout,
    setLoading
  } = useAppData() as AppContextInterface;

  usePageTracking();

  function setCurrentTime() {
    const lastDate = getCurrentDateTime()
    localStorage.setItem('lastTime', lastDate);

    const event = {
      action: 'active_user',
      value: state?.userData?.username,
      key:'active_users'
    }
    googleAnalyticsCustomEvent(event)
  }

  function findTimeDifference(startDate: Date, endDate: Date) {
    let newStartDate = (startDate.getTime() - endDate.getTime()) / 1000
    if ((newStartDate / (60 * 60)) > 6) {
      localStorage.removeItem('lastTime')
      setCurrentTime()
    }
  }

  useEffect(() => {
    if (state.session !== -1) {
      const lastSavedDate = localStorage.getItem('lastTime')
      if (!lastSavedDate) {
        setCurrentTime()
      } else {
        const startDate = new Date()
        const endDate = new Date(lastSavedDate)
        findTimeDifference(startDate, endDate)
      }
    }
  }, [state.session])

  useEffect(() => {
    setLoading(true);
    async function checkSession() {
      await checkUserSession();
    }
    checkSession()
  }, [])

  return (
    <div className="App">
      <ReactNotifications />
      <Header
        title="tappy"
        state={state}
        handleLogout={handleLogout}
        handleHistory={handleHistory}
      />
      {state.loading ? <LottieLoading /> : <></>}
      <Switch>
        <Route path="/" exact>
          {state.session !== -1
            ? <Redirect to="/dashboard" />
            : <Default handleHistory={handleHistory} />
          }
        </ Route>
        <Route path="/login" exact>
          {state.session !== -1
            ? <Redirect to="/dashboard" />
            : <Login checkUserSession={checkUserSession} setLoading={setLoading} handleHistory={handleHistory} />
          }
        </ Route>
        <Route path="/register" exact>
          {state.session !== -1
            ? <Redirect to="/editProfile" />
            : <Register state={state} handleLogout={handleLogout} checkUserSession={checkUserSession} handleHistory={handleHistory} />
          }
        </ Route>
        <Route path="/editProfile" exact>
          {state.session !== -1 ? <EditProfile state={state} setState={setState} handleHistory={handleHistory} setLoading={setLoading} /> : <Redirect to="/" />}
        </ Route>
        <Route path="/dashboard" exact>
          {state.session !== -1 ? <Dashboard state={state} setState={setState} handleHistory={handleHistory} setLoading={setLoading} /> : <Redirect to="/" />}
        </ Route>
        <Route path="/resetPassword" exact>
          <PasswordReset handleHistory={handleHistory} />
        </Route>
        <Route path="/updatePassword" exact>
          <PasswordUpdate handleHistory={handleHistory} />
        </Route>
        <Route path="/tappyActivation" exact>
          <TappyActivation state={state} handleHistory={handleHistory} />
        </Route>
        <Route path="/accountSettings" exact>
          {state.session !== -1 ? <AccountSettings handleHistory={handleHistory} state={state} setState={setState} setLoading={setLoading} /> : <Redirect to="/login" />}
        </Route>
        <Route path="/stripe" exact>
          {state.session !== -1 ? <StripeAdvertisement handleHistory={handleHistory} /> : <Redirect to="/" />}
        </Route>
        <Route path="/stripeSubscribption" exact>
          {state.session !== -1 ? <StripeSelectSubscribption handleHistory={handleHistory} /> : <Redirect to="/" />}
        </Route>
        <Route path="/addPaymentMethod" exact>
          {state.session !== -1 ?
            // subscriptionData?.plan?.id ?
            // <Redirect to="/" />
            // :
            <AddPaymentMethod handleHistory={handleHistory} goBack={goBack} />
            : <Redirect to="/login" />}
        </Route>
        <Route path="/activeSubscription" exact>
          {state.session !== -1 ? <ActiveSubscription handleHistory={handleHistory} goBack={goBack} /> : <Redirect to="/" />}
        </Route>
        <Route path="/profile" exact>
          {state.session !== -1 ? <ProfilePage state={state} setState={setState} setLoading={setLoading} handleHistory={handleHistory} /> : <Redirect to="/profile" />}
        </Route>
        <Route path="/:username" exact>
          <ProfilePage state={state} setState={setState} setLoading={setLoading} handleHistory={handleHistory} />
        </Route>
        <Redirect from='*' to='/:username' />
      </Switch>
    </div>
  );
}

export default App;
