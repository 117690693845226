import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import "./AddImageModal.scss";
import { imagesFolderName, thumbnailFolderName, videosFolderName } from "../../../utils/StaticData/staticData";
import { AppContextInterface, useAppData } from "../../../hooks/useAppData";
import { LottieLoading } from "../../LottieLoading/LottieLoading";
import { MdDelete } from "react-icons/md";
import notification from "../../Notification";

interface Props {
  setShow: (select: any) => void;
  show: boolean;
  title: string;
  modalData: Array<any>;
  handleUploadChange: (e: any, name: string) => void;
  deleteCustomPhotoVideo: (e: any, type: string) => void;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height
  };
}

const UploadIconComponent = ({ calculateHeight, showNotification, isActiveSubscription, handleUploadChange, title }: any) => (
  <div className="upload-icon-container" style={{ height: calculateHeight() }} >
    <GoPlusCircle className="upload-icon" onClick={() => showNotification(isActiveSubscription.newUser)} />
    {isActiveSubscription.active &&
      <input
        id="file"
        type="file"
        style={{ height: calculateHeight() }}
        accept={`${title}/*`}
        onChange={(e) => handleUploadChange(e, title)}
      />}
    <p className="upload-text">Upload</p>
  </div>
)

const RenderModalData = (props: any) => {
  const { item, calculateHeight, deleteCustomPhotoVideo, imageVideoUrl, handleData, showNotification, title, handleUploadChange, isActiveSubscription } = props
  const [loading, setLoading] = useState(true);

  const customHeight = useMemo(()=> calculateHeight(),[])

  return (
    <div className="image-video-inner-container" key={item.id}>
      {item?.url || item?.background_media ?
        <div style={{ width: loading ? '98%' : '100%', height: customHeight }}>
          {loading && (
            <LottieLoading showImageLoader={true} hideTappy={true} />
          )}
          {item?.background_media && !loading ? <div className="delete-icon-container" style={{ borderRadius: 25 }} onClick={() => deleteCustomPhotoVideo(item, title)}>
            <MdDelete size={22} />
          </div> : null}
          {item?.type != 'video' ? <img
            src={item?.type === 'video' ? item?.thumbnail : (item?.background_media || imageVideoUrl(item.url, thumbnailFolderName))}
            onClick={() => handleData(item?.background_media || imageVideoUrl(item.url || item?.background_media))}
            onLoad={() => setLoading(false)}
            style={{ height: '100%', width: '100%', display: loading ? 'none' : 'block' }}
          />
            :
            <video
              autoPlay={false}
              onLoadedMetadata={() => setLoading(false)}
              onClick={() => handleData(item?.background_media || imageVideoUrl(item.url || item?.background_media))}
              style={{ height: '100%', width: '100%', display: loading ? 'none' : 'block', objectFit: 'cover' }}
              preload="metadata"
            >
              <source src={`${item?.background_media}#t=0.3`} type="video/mp4" />
            </video>
          }
        </div>
        :
        <UploadIconComponent
          calculateHeight={calculateHeight}
          showNotification={showNotification}
          title={title}
          handleUploadChange={handleUploadChange}
          isActiveSubscription={isActiveSubscription}
        />
      }
    </div>
  )
}

export const AddImageModal: React.FC<Props> = (props) => {

  const { show, setShow, title, handleUploadChange, modalData, deleteCustomPhotoVideo } = props
  const {
    setProfileBackground,
    isActiveSubscription,
    setShowStripeModal
  } = useAppData() as AppContextInterface;
  const bucketName = process.env.REACT_APP_AWS_BUCKET;

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateHeight = () => {
    let width = windowDimensions.width / 3
    if (windowDimensions?.width > 500) {
      width = 500 / 3
    }
    return `${width}px`
  }

  const showNotification = (showNotify: any) => {
    if (showNotify) {
      setShow(false)
      setShowStripeModal(true)
    } else {
      notification({
        message: 'No active subscription',
        type: "danger",
      });
    }
  }

  function getThumbnailName(path: string) {
    return path.slice(0, path.lastIndexOf('.'));
  }

  function imageVideoUrl(path: string, thumbnail?: string) {
    let basePath = `https://${bucketName}.s3.amazonaws.com`
    if (title === 'video' && thumbnail) {
      basePath = `${basePath}/${videosFolderName}/${thumbnailFolderName}/${getThumbnailName(path)}-${thumbnailFolderName}.png`
    } else if (title === 'video') {
      basePath = `${basePath}/${videosFolderName}/${path}`
    } else {
      basePath = `${basePath}/${imagesFolderName}/${path}`
    }
    return basePath
  }

  function handleData(path: string) {
    setProfileBackground(path)
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      animation
      scrollable
      contentClassName="modal-content-container"
      dialogClassName="image-modal-dialog"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className="modal-header-container">
        <IoClose className="closed-icon" onClick={() => setShow(false)} />
        <Modal.Title className="custom-modal-styling-title">
          {`Background ${title}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-container">
        <div className="image-video-main-container">
          {modalData?.map((item) =>
            <RenderModalData
              item={item}
              key={item?.id}
              deleteCustomPhotoVideo={deleteCustomPhotoVideo}
              calculateHeight={calculateHeight}
              handleData={handleData}
              imageVideoUrl={imageVideoUrl}
              showNotification={showNotification}
              title={title}
              isActiveSubscription={isActiveSubscription}
              handleUploadChange={handleUploadChange}
            />)}
        </div>
      </Modal.Body>
    </Modal>
  )
}