import "./TappyActivation.scss";
import React, { useState, useEffect } from "react";
import { NotFound } from "../NotFound/NotFound"
import notification from "../Notification";
import axiosApi from "../../axiosApi";
import Button from '../Button';
import { googleAnalyticsCustomEvent } from "../../utils/google-analytics";

interface State {
  session: number;
}

interface Props {
  state: State;
  handleHistory: (url: string) => void;
}


export const TappyActivation: React.FC<Props> = (props) => {

  const [serial, setSerial] = useState(null);
  const [serialExists, setSerialExists] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const serialID: any = urlParams.get('serial');
    //console.log(serialID);

    if (!serialID) {
      notification({
        message: "Serial ID invalid, please check your activation link.",
        type: "warning",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss : {
          duration: 5000,
        }
      });

      setTimeout(() => {
        props.handleHistory('/');
      }, 3000);
    } else {
      //console.log(data);
      axiosApi.get(`card/check/${serialID}`, {headers: {'Access-Control-Allow-Origin': '*'}})
      .then((res) => {
        if (res.data.valid) {
          setSerial(serialID);
          const event = {
            action: `serial_register`,
            value: serialID,
            key: 'new_serial'
          }
          googleAnalyticsCustomEvent(event)
        } else {
          notification({
            message: "Serial ID is invalid",
            type: "warning",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            }
          });
  
          setSerialExists(false);
        }
      }).catch((err) => {
        notification({
          message: "Please Check your network or activation link.",
          type: "warning"
        });

        setSerialExists(false);
      });

    }
  }, []);

  const handleRedirect = () => {
    if (props.state.session !== -1) {
      props.handleHistory(`/accountSettings?serial=${serial}`);
    } else {
      props.handleHistory('/login');
    }
  }


  return (
    <>
    {serialExists ?
    <div className="tappy-activation">
      <span className="tappy-activation__header">Welcome to Tappy 👋</span>
      <span className="tappy-activation__subheader">It's time to upgrade to the business card of the future.</span>
      <span className="tappy-activation__serial-number">Product serial number: {serial}</span>

      <div className="tappy-activation__buttons">
        <Button 
          className="tappy-activation__new-account"
          onClick={() => {
            props.handleHistory(`/register?serial=${serial}`);
          }}
          >
          Activate to a new account</Button>
        <Button
          className="tappy-activation__existing-account"
          onClick={() => handleRedirect()}
        >Activate to an existing account</Button>
      </div>
    </div>
    : <NotFound />}
    </>
  )
}