import "./Default.scss";
import React, { useEffect } from "react";
import { Socials } from "../Socials/Socials";
import { TappyBrand } from "../TappyBrand/TappyBrand";
import { SignInButton } from "../SignInButton/SignInButton";
import { RegisterButton } from "../RegisterButton/RegisterButton";


interface Props {
  handleHistory: (url: string) => void;
}

export const Default: React.FC<Props> = (props) => {

  /**
   * Keyboard on enter key pressed event
   * Only one per page recommended
   */
  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void; }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        props.handleHistory("/login");
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <div className="homepage">
        <TappyBrand />
        <div className="homepage-container">
          <div className="homepage-call-to-action__wrapper">
            <SignInButton text="Sign in"
              handleHistory={props.handleHistory} />
            <RegisterButton text="Create an account" />
          </div>
        </div>
      </div>
      <Socials />
    </>
  );
}
