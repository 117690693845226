import "./Register.scss";
import React, { useState, useEffect } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import axiosApi from "../../axiosApi";
import { Error } from "../Error/Error";
import notification from "../Notification";
import Button from '../Button';
import { googleAnalyticsCustomEvent, googleAnalyticsEvent } from "../../utils/google-analytics";

interface State {

}

interface Props {
  checkUserSession: () => void;
  handleHistory: (url: string) => void;
  handleLogout: () => void;
  state: State;
}


export const Register: React.FC<Props> = (props) => {
  const [animationLoader, setAnimationLoader] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [tappySerialNumber, setTappySerialNumber] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const [termsAgree, setTermsAgree] = useState(false);

  const [error, setError] = useState({
    active: false,
    message: ""
  })

  /**
  * Keyboard on enter key pressed event
  * Only one per page recommended
  */
  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void; }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //console.log("Enter key was pressed.");
        event.preventDefault();
        document.getElementById('registerBtn')?.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const serialID: any = urlParams.get('serial');
    if (serialID) {
      setTappySerialNumber(serialID.toUpperCase());
    }

  }, [])

  const togglePassword = () => {
    setHidePassword(!hidePassword);
  }

  const handleRegister = async () => {

    // User name cannot be the same format as serial_id, which is either
    // AA0000, AA00000, 0000AA, 00000AA, A0000, A00000, 0000, 00000
    let serialFormat = /(^[A-Za-z]{2}\d{4}$)|(^\d{4}[A-Za-z]{2}$)|(^\d{5}[A-Za-z]{2}$)|(^[A-Za-z]{2}\d{5}$)|(^[A-Za-z]{1}\d{4}$)|(^[A-Za-z]{1}\d{5}$)|(^\d{4}$)|(^\d{5}$)/;
    if (!serialFormat.test(username)) {
      let eventData = {
        email,
        username,
        first_name: firstName,
        last_name: lastName,
        tappy_serial_number: tappySerialNumber
      }
      let data = {
        ...eventData,
        password,
        password_confirmation: passwordConfirmation,
      }
      const event = {
        action: 'registration_form',
        value: username,
        key: 'register_info'
      }

      setAnimationLoader(true)
      try {
        const response = await axiosApi.post(`user/register`, data, {
          headers: { 'Access-Control-Allow-Origin': '*' }
        });

        if (response.data.access_token) {
          googleAnalyticsCustomEvent(event)
          localStorage.setItem('accessToken', response.data.access_token);

          props.checkUserSession();

          notification({
            message: "Account successfully created",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            }
          });

          props.handleHistory("/login");
        } else {
          setError({
            active: true,
            message: response.data.error
          })
        }
        setAnimationLoader(false)

      } catch (e: any) {
        setAnimationLoader(false)
        notification({
          message: e.response.status === 422 ? e.response.data.error : "Please Check your input.",
          type: "warning",
        });
      }
    } else {
      notification({
        message: "Username Cannot be of that format",
        type: "warning",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        }
      });
    }

  }

  return (
    <div className="register-page">
      {animationLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
      <div className="register-page-description">
        <span className="register-page-description-title">Let's get started.</span>
        <span className="register-page-description-subtitle">To make an account you'll need to have purchased a tappy already. If you don't have one, purchase it <a href="#">here.</a></span>
      </div>
      <Form className="register-form">
        {error.active && (
          <Error error={error} />
        )}
        <Form.Group>
          <Form.Label>Your First Name</Form.Label>
          <Form.Control
            type="text"
            autoComplete="given-name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Your Last Name</Form.Label>
          <Form.Control
            type="text"
            autoComplete="family-name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Username (no special characters)</Form.Label>
          <Form.Control
            type="text"
            autoComplete="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)} />
          <Form.Text className="text-muted">
            Your Tappy link will be tappy.tech/{username}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            autoComplete="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)} />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={hidePassword ? "password" : "text"}
              value={password}
              onChange={(event) => setPassword(event.target.value)} />
            <InputGroup.Append onClick={() => { togglePassword() }}>
              {hidePassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown" />}
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Verify Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={hidePassword ? "password" : "text"}
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)} />
            <InputGroup.Append onClick={() => { togglePassword() }}>
              {hidePassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown" />}
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="SerialNumber">
          <Form.Label>Tappy Serial Number</Form.Label>
          <Form.Control
            type="email"
            value={tappySerialNumber}
            onChange={(event) => setTappySerialNumber(event.target.value.toUpperCase())}
            style={{ textTransform: "uppercase" }}
          />
        </Form.Group>

        <Form.Group className="disclaimer" controlId="formBasicCheckbox" onChange={(e: any) => setTermsAgree(e.target.checked)}>
          <Form.Check className="disclaimer-content text-muted">
            <Form.Check.Input type="checkbox" />
            <Form.Check.Label>By creating an account you agree to our <a href="##">Privacy Policy and Terms of Service</a></Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <div className="register-page-create-account__btn__wrapper">
          <Button className="register-create-account border-0" id="registerBtn" onClick={() => handleRegister()} disabled={!termsAgree}>
            Create account
            <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.192017 4.68964H15.648M15.648 4.68964L12.888 1.37927M15.648 4.68964L12.888 8" stroke="white" strokeWidth="2" />
            </svg>
          </Button>
        </div>
      </Form>
    </div>
  );
}
