
export const getSocialIcon = (socialTitle: any) => {
  switch (socialTitle) {
    case "Twitter":
      return "images/14twitter.png";
    case "Triller":
      return "images/23triller.png";
    case "Apple Music":
      return "images/1Applemusic.png";
    case "Calendly":
      return "images/22calendly.png";
    case "Cashapp":
      return "images/2cashapp.png";
    case "Email":
      return "images/5mail.png";
    case "Facebook":
      return "images/6facebook.png";
    case "Instagram":
      return "/images/7instagram.png";
    case "Linkedin":
      return "images/8linkedin.png";
    case "Paypal":
      return "images/9paypal.png";
    case "Phone":
      return "images/phone.png";
    case "Snapchat":
      return "images/11snap.png";
    case "Soundcloud":
      return "images/13soundcloud.png";
    case "Spotify Account":
      return "images/12spotify.png";
    case "Spotify Playlist":
      return "images/12spotify.png";
    case "Tiktok":
      return "images/10tiktok.png";
    case "Twitch":
      return "images/15twitch.png";
    case "Venmo":
      return "images/16venmo.png";
    case "Youtube":
      return "images/18youtubechannel.png";
    case "Kick":
      return "images/14kick.png";
    case "Rumble":
      return "images/15rumble.png";
    case "Youtube Video":
      return "images/youtube_video_icon_128px.png";
    default:
      return "images/17customurl_website.png";
  }
};

export const getCurrentDateTime = () => {
  return new Date().toString()
}

export const checkSerialNumber = (num: any) => {
  let serialFormat =
    /(^[A-Za-z]{2}\d{4}$)|(^\d{4}[A-Za-z]{2}$)|(^\d{5}[A-Za-z]{2}$)|(^[A-Za-z]{2}\d{5}$)|(^[A-Za-z]{1}\d{4}$)|(^[A-Za-z]{1}\d{5}$)|(^\d{4}$)|(^\d{5}$)/;
  if (serialFormat.test(num)) {
    return true
  }
  return false
}