import React, { useEffect, useState } from "react";
import './StripeTrialModal.scss'

import { Modal } from "react-bootstrap";
import { StripeAdvertisementComp } from "../../components/Stripe/StripeAdvertisement/StripeAdvertisementComp";
import { StripeSelectSubscribptionComp } from "../../components/Stripe/StripeSelectSubscribption/StripeSelectSubscribptionComp";
import { modalSteps } from "../../utils/StaticData/staticData";
import { AddPaymentMethodComp } from "../../components/Stripe/AddPaymentMethod/AddPaymentMethodComp";

interface Props {
  hideModal: () => void;
  showStripeModal: boolean;
  handleHistory: any;
}


export const StripeTrialModal: React.FC<Props> = (props) => {
  const { handleHistory, showStripeModal, hideModal } = props
  const [steps, setSteps] = useState({ type: modalSteps[0], param: '' })

  useEffect(() => {
    if (showStripeModal) {
      setSteps({ type: modalSteps[0], param: '' })
    }
  }, [showStripeModal])

  const params = {
    alreadyRegistered: false
  }

  return (
    <Modal
      show={showStripeModal}
      className='main-stripe-modal-header'
      contentClassName='main-stripe-modal-content'
      onHide={hideModal}
      centered
    >
      <Modal.Body className="main-stripe-modal-body">
        {steps.type === modalSteps[0] && <StripeAdvertisementComp props={handleHistory} steps={steps} setSteps={setSteps} hideModal={hideModal}/>}
        {steps.type === modalSteps[1] && <StripeSelectSubscribptionComp {...props} params={params} steps={steps} setSteps={setSteps} />}
        {steps.type === modalSteps[2] && <AddPaymentMethodComp {...props} params={steps.param} steps={steps} setSteps={setSteps}/>}
      </Modal.Body>
    </Modal>
  )
}

