// @ts-nocheck
import '../StripeSelectSubscribption/StripeSelectSubscribption.scss'
import './ActiveSubscription.scss'
import React, { useEffect, useRef, useState } from "react";
import { Button } from 'react-bootstrap';
import { IoCard } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import Overlay from 'react-bootstrap/Overlay';
import { MdMoreHoriz } from "react-icons/md";
import { RxBorderDotted } from "react-icons/rx";
import { ManageSubscription } from '../ManageSubscription/ManageSubscription';
import { AppContextInterface, useAppData } from '../../../hooks/useAppData';
import axiosApi from '../../../axiosApi';
import notification from "../../Notification";
import { LottieLoading } from '../../LottieLoading/LottieLoading';
import { ConfirmationModal } from '../../ConfirmationModal/ConfirmationModal';
interface Props {
  handleHistory: (url: string, params?: any) => void;
  goBack: () => void;
}

const PaymentMethods = (props: any) => {

  const { subscriptionData, item, show, setShow, setDataId, dataId, setDefaultCard } = props
  const target = useRef(null);
  const isDefault = subscriptionData?.defaultPaymentMethod == item.id ? true : false
  return (
    <div className={`displayFlex flexStart card-list-container ${isDefault ? 'defaultMethod' : null}`}>
      <div className='icon-container'>
        <IoCard style={{ width: '100%', height: '100%' }} />
      </div>
      <p className='normal-text'>Card</p>
      <RxBorderDotted className='four-dot' size={35} color='#93A1A9' />
      <p className='normal-text light-color'>{item.card?.last4}</p>
      <div className='edit-icons'>
        {isDefault && <p className='normal-text small default-btn'>default</p>}
        {!isDefault &&
          <div ref={target} onClick={() => {
            setDataId(item.id, false)
            setShow(dataId === item.id ? !show : true)
          }}>
            <MdMoreHoriz size={30} color='#8F9697' />
          </div>
        }
      </div>
      {dataId === item.id && <Overlay target={target.current} show={show} placement="left">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              backgroundColor: '#416AA7',
              padding: '2px 10px',
              color: 'white',
              borderRadius: 8,
              ...props.style,
            }}
          >
            <div onClick={() => setDefaultCard()}>
              <p className='normal-text light medium moreOptions'>Set as default</p>
            </div>
            <div onClick={() => {
              setDataId(item.id, true)
              setShow(false)
            }}>
              <p className='normal-text light medium moreOptions '>remove</p>
            </div>
          </div>
        )}
      </Overlay>}
    </div>
  )
}

export const ActiveSubscription: React.FC<Props> = (props) => {

  const {
    subscriptionDetails,
    subscriptionData,
    setSubscriptionData,
  } = useAppData() as AppContextInterface;

  const [show, setShow] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [animationLoader, setAnimationLoader] = useState<boolean>(false);
  const [switchPlanModal, setSwitchPlanModal] = useState<boolean>(false);
  const [formattedDate, setFormattedDate] = useState<any>(null);
  const [billingDate, setBillingDate] = useState<any>(null);
  const [userBillingDetails, setUserBillingDetails] = useState<any>({});
  const [confimationDialog, setConfimationDialog] = useState<any>({
    showDialog: false,
    dataId: null
  });
  const buttonRef = useRef<any>(null)
  const manageSubModalRef = useRef<any>(null)

  const { dataId, showDialog } = confimationDialog

  const hideModal = () => setShowModal(false)
  const hideDialog = () => setConfimationDialog((prev: any) => ({ ...prev, showDialog: !showDialog }))
  const setDataId = (value?: any, modalState?: boolean) => setConfimationDialog((prev: any) => ({ ...prev, dataId: value, showDialog: modalState }))
  const isActiveSubscription = (subscriptionData?.subscriptions?.data?.[0]?.status === "active" || subscriptionData?.subscriptions?.data?.[0]?.status === "trialing") ? true : false

  useEffect(() => {
    if (subscriptionData?.paymentCard?.length > 0) {
      const index = subscriptionData?.paymentCard?.findIndex((item: any) => item.id == subscriptionData?.defaultPaymentMethod)
      if (index !== -1) {
        setUserBillingDetails(subscriptionData.paymentCard[index])
      }
      getSubscriptionEndDate(subscriptionData?.subscriptions?.data)
    }
  }, [subscriptionData])

  const formatDate = (givenDate) => {
    const timestamp = givenDate;
    const dateObject = new Date(timestamp * 1000);
    return dateObject.toLocaleDateString();
  }

  const getSubscriptionEndDate = (subscriptionData) => {
    const subData = subscriptionData?.[0]
    if (subData?.cancel_at_period_end === true) {
      setFormattedDate(formatDate(subData?.current_period_end));
      setBillingDate(null)
    } else if (subData?.cancel_at_period_end === false && subData.status === "trialing") {
      setBillingDate(formatDate(subData?.current_period_end));
      setFormattedDate(null)
    } else {
      setBillingDate(null)
      setFormattedDate(null)
    }
  }


  const handleAddress = (addressData: any) => {
    let address = ''
    if (addressData?.line1) {
      address += addressData?.line1
    }
    if (addressData?.line2?.length > 0) {
      address += (', ' + addressData?.line2)
    }
    if (addressData?.city) {
      address += (', ' + addressData?.city)
    }
    if (addressData?.state) {
      address += (', ' + addressData?.state)
    }
    if (addressData?.country) {
      address += (', ' + addressData?.country)
    }
    if (addressData?.postal_code) {
      address += (', ' + addressData?.postal_code)
    }
    return address
  }

  const removeCard = (dataId: any) => {
    const index = subscriptionData?.paymentCard?.findIndex((item: any) => item.id == dataId)
    if (index !== -1) {
      let newData = [...subscriptionData?.paymentCard]
      newData.splice(index, 1)
      setSubscriptionData({ ...subscriptionData, paymentCard: newData })
    }
  }

  const deleteCard = async () => {
    setAnimationLoader(true)
    hideDialog()
    if (dataId !== null) {
      try {
        const response = await axiosApi.post(`user/payment/method/card/delete`, { paymentMethodId: dataId });
        console.log('response:::', response);
        if (response.status === 200) {
          removeCard(dataId)
          notification({
            message: response?.data?.message,
            type: "success",
          });
        } else {
          notification({
            message: "Something went wrong!",
            type: "danger",
          });
        }
        setDataId(null, false)
        setAnimationLoader(false)
      } catch (e) {
        setDataId(null, false)
        setAnimationLoader(false)
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    }
  }

  const handleDefaultCard = () => {
    let newData = { ...subscriptionData }
    newData.defaultPaymentMethod = dataId
    setSubscriptionData(newData)
  }

  const setDefaultCard = async () => {
    setAnimationLoader(true)
    if (dataId !== null) {
      try {
        const response = await axiosApi.post(`user/payment/method/card/default`, { new_payment_method_id: dataId });
        if (response.status === 200) {
          notification({
            message: response?.data?.message,
            type: "success",
          });
          handleDefaultCard()
        } else {
          notification({
            message: "Something went wrong!",
            type: "danger",
          });
        }
        setAnimationLoader(false)
      } catch (e) {
        setAnimationLoader(false)
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    }
  }

  const cancelSubscription = async () => {
    setAnimationLoader(true)
    hideModal()
    try {
      const response = await axiosApi.get(`user/subscription/customer/cancel`);
      if (response.status === 200) {
        subscriptionDetails(() => {
          buttonRef?.current?.click()
          setAnimationLoader(false)
          notification({
            message: response?.data?.message,
            type: "success",
          });
        })
      } else {
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
        setAnimationLoader(false)
      }
    } catch (e) {
      setAnimationLoader(false)
      notification({
        message: "Something went wrong!",
        type: "danger",
      });
    }
  }

  const pauseSubscriptionPlan = async () => {
    hideModal()
    if (subscriptionData?.subscriptions?.data?.[0]?.status === "trialing") {
      notification({
        message: "Pause subscription is not available on trial period!",
        type: "danger",
      });
    } else {
      try {
        setAnimationLoader(true)
        const response = await axiosApi.get(`user/subscription/customer/plan/pause`);
        if (response.status === 200) {
          subscriptionDetails(() => {
            setShowModal(true)
            setTimeout(() => {
              manageSubModalRef?.current?.pauseSubsPlan()
            }, 0);
            setAnimationLoader(false)
            notification({
              message: 'Added 30 days to the current subscription!',
              type: "success",
            });
          })
        } else {
          notification({
            message: "Something went wrong!",
            type: "danger",
          });
          setAnimationLoader(false)
        }
      } catch (e) {
        console.log('eeee:::', e);

        setAnimationLoader(false)
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    }
  }

  const upgradeSubscriptionPlan = async (id: any) => {
    try {
      const response = await axiosApi.post(`user/subscription/customer/plan/change`, { planId: id });
      if (response.status === 200) {
        subscriptionDetails(() => {
          setAnimationLoader(false)
          notification({
            message: response?.data?.message,
            type: "success",
          });
        })
      } else {
        notification({
          message: "Something went wrong!",
          type: "danger",
        });
        setAnimationLoader(false)
      }
    } catch (e) {
      setAnimationLoader(false)
      notification({
        message: "Something went wrong!",
        type: "danger",
      });
    }
  }

  const getTappyPlans = async () => {
    setAnimationLoader(true)
    setSwitchPlanModal(false)
    try {
      const response = await axiosApi.get('user/plans')
      if (response.data.status) {
        const findId = response?.data?.data.find((item) => item.slug === 'annual')
        upgradeSubscriptionPlan(findId?.id)
      } else {
        setAnimationLoader(false)
      }
    } catch (err) {
      notification({
        message: "Something went wrong!",
        type: "danger",

      });
      setAnimationLoader(false)
    }
  }

  return (
    <div className="subscribption subscribption-main">
      <div className='subscibption-container' onClick={() => setShow(false)}>
        <p className='normal-text bold heading alignText'>Current plan:</p>
        {subscriptionData?.subscriptions?.data?.length > 0 ?
          <>{isActiveSubscription ?
            <>
              <div className='displayFlex flexStart alignItemStart heading-text-container'>
                <p className='heading-text light'>tappy</p>
                <p className='heading-text light'>pro - {subscriptionData?.plan?.slug || ''}</p>
              </div>
              <p className='heading-text'>${subscriptionData?.plan?.price || ''}/{subscriptionData?.plan?.slug === 'monthly' ? 'month' : 'year'}</p>
            </>
            :
            <>
              <div className='displayFlex flexStart alignItemStart heading-text-container'>
                <p className='heading-text'>No Plan</p>
              </div>
              <div>
                <p className='heading-text veryLight'>Payment Failed!</p>
                <p className='heading-text veryLight underline' onClick={() => props?.handleHistory('/stripeSubscribption', { alreadyRegistered: true, paymentMethod: userBillingDetails })}>Try Again</p>
              </div>
            </>
          }
          </>
          :
          <>
            <div className='displayFlex flexStart alignItemStart heading-text-container'>
              <p className='heading-text'>No Plan</p>
            </div>
            <div>
              <p className='heading-text veryLight'>Your plan has expired!</p>
              <p className='heading-text veryLight underline' onClick={() => props?.handleHistory('/stripeSubscribption', { alreadyRegistered: true, paymentMethod: userBillingDetails })}>Renew now</p>
            </div>
          </>
        }
        {subscriptionData?.subscriptions?.data?.length > 0 ?
          <>
            {formattedDate !== null ?
              <div>
                <p className='manage-subscription'>Your subscription ends on {formattedDate}</p>
              </div>
              :
              <>
                {isActiveSubscription ?
                  <div onClick={() => setShowModal(true)}>
                    <p className='manage-subscription underline'>manage subscription</p>
                  </div>
                  :
                  null
                }
              </>
            }
            {billingDate !== null && <div>
              <p className='manage-subscription'>Your billing starts from {billingDate}</p>
            </div>}
          </>
          :
          null
        }
      </div>
      {subscriptionData?.plan?.slug !== "annual" && isActiveSubscription &&
        <Button
          className='switch-btn'
          variant="primary"
          size='lg'
          onClick={() => setSwitchPlanModal(true)}
        >switch to annual billing, save 28%</Button>
      }
      <p className='normal-text bold heading alignText'>payment methods:</p>
      <div className='displayFlex column'>
        {subscriptionData?.paymentCard?.map((item: any) =>
          <PaymentMethods
            item={item}
            show={show}
            dataId={dataId}
            setDataId={setDataId}
            setShow={setShow}
            subscriptionData={subscriptionData}
            setDefaultCard={setDefaultCard}
          />)}
      </div>
      <div onClick={() => props?.handleHistory('addPaymentMethod', { add: true })}>
        <p className='normal-text light sub-heading bottom'>+ Add payment method</p>
      </div>
      <p className='normal-text bold main-heading alignText'>billing information:</p>
      <div className='displayFlex flexStart'>
        <p className='user-details'>Name:</p>
        <p className='user-details user-info'>{userBillingDetails?.billing_details?.name}</p>
      </div>
      <div className='displayFlex flexStart'>
        <p className='user-details'>Billing address:</p>
        <p className='user-details user-info'>{handleAddress(userBillingDetails?.billing_details?.address)}</p>
      </div>
      <div className='displayFlex flexStart' onClick={() => props?.handleHistory('addPaymentMethod', { edit: userBillingDetails })}>
        <MdEdit size={18} className='edit-icon' />
        <p className='normal-text light medium alignText'>Update information</p>
      </div>
      <ManageSubscription
        showModal={showModal}
        hideModal={hideModal}
        cancelSubscription={cancelSubscription}
        pauseSubscriptionPlan={pauseSubscriptionPlan}
        handleHistory={props?.handleHistory}
        subscriptionData={subscriptionData}
        billingDate={billingDate}
        ref={manageSubModalRef}
      />
      <ConfirmationModal
        showModal={switchPlanModal}
        hideModal={() => setSwitchPlanModal(!switchPlanModal)}
        deleteCard={getTappyPlans}
        subHeading={'You will be charged US $53.01 for the annual plan.'}
        leftBtn={'Cancel'}
        rightBtn={'Subscribe'}
        heading={'Annual Plan'}
        color={'success'}
      />
      <ConfirmationModal
        showModal={showDialog}
        hideModal={hideDialog}
        deleteCard={deleteCard}
        heading={'Delete!'}
        subHeading={'Are you sure want to delete this payment method?'}
        leftBtn={'Cancel'}
        rightBtn={'Yes, delete it!'}
        color={'danger'}
      />
      {animationLoader ? <LottieLoading /> : <></>}
      <button
        style={{ display: 'none' }}
        data-tally-open="meaVqx"
        ref={buttonRef}
        data-tally-layout="modal"
        data-tally-width="363"
        data-tally-emoji-text="👋"
        data-tally-emoji-animation="wave"
        data-tally-auto-close="1000"
      ></button>
    </div>
  );
}