import "./ProfileSocial.scss";
import React from "react";
import { AppContextInterface, useAppData } from "../../hooks/useAppData";
import { shortenString } from "../../utils/google-analytics";

interface Props {
  username: string;
  name: string;
  imgSrc: string;
  socialLink: string;
  profile: any;
  button_color: any;
}

const getSocialDisplay = (socialTitle: any) => {
  switch (socialTitle.name) {
    case "Twitter":
      return socialTitle.username;
    case "Apple Music":
      return socialTitle.username;
    case "Calendly":
      return "Calendly - Schedule a Call";
    case "Cashapp":
      return socialTitle.username.includes("cash.app/$")
        ? socialTitle.username.split("$")[1]
        : "Cashapp";
    case "Email":
      return socialTitle.username;
    case "Facebook":
      return socialTitle.username;
    case "Instagram":
      return socialTitle.username;
    case "Linkedin":
      return "LinkedIn Profile";
    case "Paypal":
      return socialTitle.username;
    case "Phone":
      return socialTitle.username;
    case "Snapchat":
      return socialTitle.username;
    case "Soundcloud":
      return socialTitle.username
        .replace("https://", "")
        .replace("soundcloud.com/", "");
    case "Spotify Account":
      return socialTitle.username
        .includes("open.spotify.com")
        ? "Spotify" : socialTitle.username;
    case "Tiktok":
      return socialTitle.username;
    case "Triller":
      return socialTitle.username;
    case "Twitch":
      return socialTitle.username;
    case "Venmo":
      return "Venmo";
    case "Youtube":
      return socialTitle.username;
    case "Youtube Video":
      return socialTitle.username;
    case "Website":
      return socialTitle.username;
    default:
      return socialTitle.username;
  }
};

export const ProfileSocial: React.FC<Props> = (props) => {
  const {
    buttonShape,
  } = useAppData() as AppContextInterface;

  return (
    <a href={props.socialLink} className="profile-social" target="__blank">
      <div className="profile-social-img__wrapper">
        <img
          src={props.imgSrc}
          className="profile-social-img"
          alt="socialImage"
        />
      </div>
      <div
        className={`profile-social-username__wrapper social-userName-character button-live-preview-${buttonShape}`}
        style={{
          backgroundColor: `rgba(${props.button_color?.r}, ${props.button_color?.g}, ${props.button_color?.b}, ${props.button_color?.a})`,
          backdropFilter: 'blur(3px)',
          WebkitBackdropFilter: 'blur(3px)',
          borderColor: props?.profile?.button_stroke,
        }}
      >
        <span
          className={`profile-social-username`}
          style={{
            color: props?.profile?.accent_text_color,
            fontFamily: props?.profile?.font_family,
          }}
        >
          {shortenString(getSocialDisplay(props), 16)}
        </span>
      </div>
    </a>
  );
};
