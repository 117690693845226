import "./UploadPhoto.scss"
import React, { useRef } from "react";
import * as aws from "aws-sdk";
import notification from "../Notification";
import { AppContextInterface, useAppData } from "../../hooks/useAppData";
import Button from '../Button';

interface Props {
  saveProfilePhoto: (url: string) => void;
  userId: number;
  hideImageLoader: () => void;
  showImageLoader: () => void;
}

export const UploadPhoto: React.FC<Props> = (props) => {

  const {
    uploadOnS3
  } = useAppData() as AppContextInterface;
  const imageUpload = useRef<HTMLInputElement>(null);

  const bucketName = process.env.REACT_APP_AWS_BUCKET;

  const onUploadImageClick = () => {
    if (imageUpload && imageUpload.current) {
      imageUpload.current.click();
    }
  }

  const onUploadImage = async (e: any) => {
    e.preventDefault()
    const imageFiles = e.target.files;
    props?.showImageLoader()
    const fileName = props.userId.toString() + "_avatar_" + imageFiles?.[0]?.name.replace(" ", "");

    const params = ({
      Bucket: bucketName ? bucketName : '',
      Key: fileName ? fileName : '',
      Body: imageFiles[0] ? imageFiles[0] : null
    })

    if (!imageFiles.length) {
      props?.hideImageLoader()
      notification({
        message: "Choose a File to Upload",
        type: "info",

      });
      return false
    }
    uploadOnS3(params, (status: boolean, progress: number) => {
      if (status) {
        let imageURL = `https://${bucketName}.s3.amazonaws.com/${fileName}`;
        props.saveProfilePhoto(imageURL);
        notification({
          message: "Image Successfully Uploaded",
          type: "success",
        });
      } else if (!status && typeof progress === 'undefined') {
        props?.hideImageLoader()
        notification({
          message: "Image Upload Failed",
          type: "danger",
        });
      }
    })

  };


  return (
    <>
      <input
        className="image-upload-input"
        ref={imageUpload}
        type="file"
        accept="image/*"
        onChange={onUploadImage} />
      <Button
        className="edit-profile-upload-photo__btn"
        onClick={onUploadImageClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 29 25" fill="none">
          <path d="M25.5803 9.59994L20.4142 2.96624L22.62 1.26431C23.224 0.798311 23.9155 0.615819 24.6946 0.716838C25.4727 0.817736 26.0943 1.1705 26.5595 1.77513L28.2582 3.98335C28.7234 4.58798 28.9185 5.27025 28.8436 6.03016C28.7688 6.78913 28.4294 7.40162 27.8255 7.86762L25.5803 9.59994ZM23.2912 11.3976L6.59015 24.2835L0.663572 23.515L1.43316 17.58L18.1342 4.69403L23.2912 11.3976Z" fill="white" />
        </svg>
      </Button>
    </>
  )
}