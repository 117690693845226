import "./EditProfile.scss";
import React, { useState } from "react";
import { Form, Container, Dropdown, Tab, Tabs, Spinner } from "react-bootstrap";
import { EditSocial } from "../EditSocial/EditSocial";
import { AiFillPlusCircle } from "react-icons/ai";
import { EditContactCard } from "../EditContactCard/EditContactCard";
import { UploadPhoto } from "../UploadPhoto/UploadPhoto";
import axiosApi from "../../axiosApi";
import { Error } from "../Error/Error";
import notification from "../Notification";
import 'react-notifications-component/dist/theme.css';
import { useEffect } from "react";
import { LottieLoading } from "../LottieLoading/LottieLoading";
import { EditTheme } from "../EditTheme/EditTheme";
import { useAppData, AppContextInterface } from "../../hooks/useAppData";
import Button from '../Button';
import { ProfileSettings } from "../ProfileSettings/ProfileSettings";

interface ContactCard {
  id: number;
  profile_id: number;
  first_name: string;
  last_name: string;
  company: string;
  phones: {
    cell_phone: string[],
    pager_phone: string[],
    work_phone: string[],
    home_phone: string[]
  };
  email: string;
  website: string;
  address: string;
  birthday: string;
  notes: string;
  pin_active: boolean;
  security_pin: string;
  public: boolean;
}

interface Pivot {
  id: number,
  profile_id: number,
  social_id: number;
  username: string;
  website_url: string;
}

interface Social {
  id: number;
  name: string;
  pivot: Pivot;
  showing: boolean;
}

interface Profile {
  id: number;
  user_id: number;
  name: string;
  bio: string;
  photo: string;
  created_at: string;
  updated_at: string;
  social_order: string;
  socials: Social[] | null;
  pin_active: boolean;
  contactCard: ContactCard;
  theme: string;
  accent_color: string;
  header_logo: string | null;
  banner_image: string | null;
}

interface State {
  session: number;
  userData: {};
  profiles: Profile[] | null;
  socials: any;
  selectedProfile: Profile;
}

interface Props {
  state: State;
  setState: React.Dispatch<React.SetStateAction<any>>;
  handleHistory: (url: string) => void;
  setLoading: (status: boolean) => void;
}

export const EditProfile: React.FC<Props> = (props) => {
  const {
    accentColor,
    accentTextColor,
    profileBackground,
    theme,
    buttonShape,
    buttonColor,
    buttonStrokes,
    hideTappyFooter,
    receiveContact,
    fontFamily,
    setSelectedDesignTab,
    animLoader,
    uploadLoader,
    setSocialState,
    socialsState
  } = useAppData() as AppContextInterface;
  const [fullName, setFullName] = useState(props.state.selectedProfile ? props.state.selectedProfile.name : "");
  const [profilePhoto, setProfilePhoto] = useState(props.state.selectedProfile ? props.state.selectedProfile.photo : "");
  const [bio, setBio] = useState(props.state.selectedProfile ? props.state.selectedProfile.bio : "");
  //Edit contact card
  const [toggleEditContactCard, setToggleEditContactCard] = useState(false);
  const [animationLoader, setAnimationLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(true);

  const [error, setError] = useState({
    active: false,
    message: ""
  })

  useEffect(() => {
    if (props.state.selectedProfile) {
      props.state.selectedProfile.bio && props.state.selectedProfile.bio !== bio && setBio(props.state.selectedProfile.bio);
      props.state.selectedProfile.photo && props.state.selectedProfile.photo !== profilePhoto && setProfilePhoto(props.state.selectedProfile.photo);
      props.state.selectedProfile.name && props.state.selectedProfile.name !== fullName && setFullName(props.state.selectedProfile.name);
    }
  }, [props.state.selectedProfile]);

  const hideImageLoader = () => setImageLoader(false);
  const showImageLoader = () => setImageLoader(true);

  const saveProfilePhoto = async (url: string) => {
    setProfilePhoto(url);

    const accessToken = localStorage.getItem('accessToken');

    if (props.state.selectedProfile && accessToken) {

      const data = {
        name: fullName,
        bio: bio,
        photo: url,
        social_order: ''
      }

      try {
        const profileResponse = await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        hideImageLoader()
        if (profileResponse.data.error) {
          setError({
            active: true,
            message: profileResponse.data.error
          })
        } else {
          //Set new socials state
          //setState
          let oldProfile = { ...props.state.selectedProfile };
          if (oldProfile) {
            oldProfile.photo = url;
            props.setState(() => ({
              ...props.state,
              selectedProfile: oldProfile
            }));
          }
          notification({
            message: "Profile Successfully Updated",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1000,
            }
          });
        }
      } catch (err: any) {
        hideImageLoader()
        notification({
          message: err?.response?.data?.message || 'Error',
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
          }
        });
      }
      //setTimeout(function(){ window.location.href = "/" }, 1500);
    }

  }

  const updateSocialState = (index: number, value: string) => {
    let currentSocialState = JSON.parse(JSON.stringify(socialsState));
    currentSocialState[index].pivot.username = value;
    setSocialState(currentSocialState);
  }

  const updateWebsiteName = (index: number, value: string) => {
    let currentSocialState = JSON.parse(JSON.stringify(socialsState));
    currentSocialState[index].pivot.website_url = value;
    setSocialState(currentSocialState);
  }

  const onAddSocial = (socialName: string, socialID: number) => {
    let currentSocialState = JSON.parse(JSON.stringify(socialsState));

    if (currentSocialState && props.state.socials) {
      for (let i = 0; i < props.state.socials.length; i++) {
        if (props.state.socials[i].name === socialName) {
          if (socialName == "Website" || socialName == "Spotify Playlist" || socialName == "Youtube Video" || socialName == "Youtube Channel") {
            currentSocialState.push({
              ...props.state.socials[i],
              pivot: { profile_id: props.state.selectedProfile?.id, username: "", social_id: props.state.socials[i].id, website_url: "" }
            });
          }
          else {
            currentSocialState.push({
              ...props.state.socials[i],
              pivot: { profile_id: props.state.selectedProfile?.id, username: "", social_id: props.state.socials[i].id }
            });
          }

        }
      }
      setSocialState(currentSocialState);
    }
  }

  const onRemoveSocial = (key: number) => {
    let currentSocialState = JSON.parse(JSON.stringify(socialsState));

    if (currentSocialState && props.state.socials) {
      currentSocialState.splice(key, 1);
      setSocialState(currentSocialState);
    }
  }

  /*
  const updateProfile = async () => {

    const accessToken = localStorage.getItem('accessToken');
    setAnimationLoader(true);

    if (props.state.selectedProfile && accessToken) {
      console.log("T", socialsState);

      var socialOrder = '';
      for(var i = 0; i < socialsState.length; i++) {
        if(i+1 !== socialsState.length) {
          socialOrder += socialsState[i].pivot.id + ",";
        }
        else {
          socialOrder += socialsState[i].pivot.id;
        }
      }
      try {
        const data = {
          name: fullName,
          bio: bio,
          photo: profilePhoto,
          social_order:socialOrder,
        }
  
        const profileResponse = await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        console.log('update profile response', profileResponse);
        
        if (profileResponse.data.error) {
          setError({
            active: true,
            message: profileResponse.data.error
          })
        } else {
          setBio(profileResponse.data.bio);
          setFullName(profileResponse.data.name);
          setProfilePhoto(profileResponse.data.photo);
          props.setState(() => ({
            ...props.state,
            selectedProfile: profileResponse.data
          }));
        }

      } catch (err: any) {
        console.log(err.response);
        //setAnimationLoader(false);
      }
  
    } else {
      console.log('No session or profile found.');
      //setAnimationLoader(false);
    }
  }
  */
  const handleSaveProfile = async () => {
    var format = /[*!?&@#$%^|\":<>[\]{}`\\();~+]/;
    setSelectedDesignTab("0")

    const accessToken = localStorage.getItem('accessToken');
    // checking if spotify url is as expected
    for (let i = 0; i < socialsState.length; i++) {
      var pattern = new RegExp(format);
      if ((socialsState[i]?.name?.toString()?.toLowerCase()?.includes("spotify account") && !socialsState[i]?.pivot?.username?.includes('open.spotify.com'))
        || (socialsState[i]?.name?.toString()?.toLowerCase()?.includes("spotify playlist") && !socialsState[i]?.pivot?.website_url?.includes('open.spotify.com'))
        || (socialsState[i]?.name?.toString()?.toLowerCase()?.includes("soundcloud") && !socialsState[i]?.pivot?.username?.includes('soundcloud.com'))) {
        notification({
          message: "Unverified URL",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 7000,
          }
        });
        return;
      }
      else if ((socialsState[i]?.name?.toString()?.toLowerCase() === 'instagram' || socialsState[i]?.name?.toString()?.toLowerCase() === 'tiktok' || socialsState[i]?.name?.toString()?.toLowerCase() === 'snapchat') && pattern.test(socialsState[i]?.pivot?.username)) {
        notification({
          message: "Please remove special characters - only -, _ and . are allowed.",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 7000,
          }
        });
        return;
      }
      else if (socialsState[i]?.pivot?.username == "") {
        notification({
          message: "Please don't leave input field empty",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 7000,
          }
        });
        return;
      }
      else if (socialsState[i]?.name?.toString()?.toLowerCase() === 'snapchat' && socialsState[i]?.pivot?.username?.length < 3) {
        notification({
          message: "Snapchat username is too short",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 7000,
          }
        });
        return;
      }
      else if ((socialsState[i]?.name?.toString()?.toLowerCase() === 'tiktok' && socialsState[i]?.pivot?.username?.length > 50) ||
        (socialsState[i]?.name?.toString()?.toLowerCase() === 'instagram' && socialsState[i]?.pivot?.username?.length > 50) ||
        (socialsState[i]?.name?.toString()?.toLowerCase() === 'snapchat' && socialsState[i]?.pivot?.username?.length > 50)) {

        notification({
          message: "Username is too long",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 7000,
          }
        });
        return;
      }
    }

    setAnimationLoader(true);
    if (props.state.selectedProfile && accessToken) {
      const data = {
        theme: theme,
      };
      axiosApi
        .post(
          `user/${props.state.session}/profile/${props.state.selectedProfile.id}/theme`,
          data
        )
        .then((res: any) => {
          //console.log(res.data);
          /**
           * This way it won't trigger refresh on selectedProfile change
           */
          props.setState({
            ...props.state,
            selectedProfile: {
              ...props.state.selectedProfile,
              theme: res.data.theme,
            },
          });
        })
        .catch((err: any) => {
          notification({
            message: err.response.data,
            type: "warning"
          });
        });
    }
    let profileData = {}
    try {
      profileData = {
        accent_color: accentColor,
        accent_text_color: accentTextColor,
        background_color: profileBackground,
        backgound_image: '',
        background_video: '',
        button_shape: buttonShape,
        button_color: buttonColor,
        button_stroke: buttonStrokes,
        header_logo: props.state.selectedProfile?.header_logo,
        banner_image: props.state.selectedProfile?.banner_image,
        receive_contact: receiveContact,
        hide_tappy_footer: hideTappyFooter,
        font_family: fontFamily,
      };
      await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}/update-profile-design`, profileData);
    } catch (e: any) {
      notification({
        message: e?.message || e?.response?.data,
        type: "warning"
      });
    }

    if (socialsState && accessToken) {
      /**
       * Adding newly added socials in order of socialsState
       */
      try {
        const response = await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}/socials`, { socialsState }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (!response.data.error) {
          for (let i = 0; i < socialsState.length; i++) {
            socialsState[i].pivot = response.data.profile[i];
          }
        }
      } catch (err: any) {
        console.log(err.response);

        setError({
          active: true,
          message: "Cannot update social"
        })
      }

      /**
       * Update both local states and global states to new profile updated
       */

      // Get values from new profile: bio, fullName, photo and socialOrders
      let newProfile = { ...props.state.selectedProfile };
      newProfile.bio = bio;
      newProfile.name = fullName;
      newProfile.photo = profilePhoto;
      newProfile!.social_order = '';
      newProfile.socials = socialsState;

      if (newProfile) {

        props.setState(() => ({
          ...props.state,
          selectedProfile: { ...newProfile, ...profileData },
        }));
      }

      try {
        const data = {
          name: newProfile.name,
          bio: newProfile.bio,
          photo: newProfile.photo,
          social_order: '',
        }

        const profileResponse = await axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile!.id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (profileResponse.data.error) {
          setError({
            active: true,
            message: profileResponse.data.error
          })
        }

      } catch (err: any) {
        console.log(err.response);
        setAnimationLoader(false);
      }
    }
    setAnimationLoader(false);
    notification({
      message: "Profile Successfully Updated",
      type: "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
      }
    });
  }

  const handleTogglePrivacy = () => {
    const accessToken = localStorage.getItem('accessToken');

    if (props.state.selectedProfile && accessToken) {
      let privacy = props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.public;
      const data = {
        public: !privacy
      }
      //console.log(data);

      axiosApi.post(`user/${props.state.session}/profile/${props.state.selectedProfile.id}/contact/togglePublic`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then((res: any) => {

        props.setState({
          ...props.state,
          selectedProfile: {
            ...props.state.selectedProfile,
            contactCard: {
              ...props.state.selectedProfile?.contactCard,
              public: res.data.public === true ? true : false
            }
          }
        });

        notification({
          message: "Privacy Updated",
          type: "success",

        });

      }).catch((err) => {
        notification({
          message: "Failed to update privacy",
          type: "warning",

        });
      });

    }
  };

  const [cursorProp, setCursorProp] = useState(null);

  return (
    <div className={`edit-profile-page edit-profile ${props.state && props.state.selectedProfile && props.state.selectedProfile.theme ? props.state.selectedProfile.theme : 'dark'}`}>
      {uploadLoader ? <div className="background-upload-loader"><Spinner animation="border" /></div> : null}
      {!animationLoader &&
        <Container>

          <Form>
            <Tabs
              defaultActiveKey="content"
              id="uncontrolled-tab-example"
              className="mb-3 profile-tabs"
            >
              <Tab eventKey="content" title="Content" className="edit-profile-tab">
                <div className="edit-profile-header">
                  <div className="edit-profile__img">
                    {imageLoader && <img className="avatar-preloader" src={'/images/avatar-loading.png'} />}
                    {props.state.selectedProfile && props.state.selectedProfile.photo
                      && props.state.selectedProfile.photo !== "" && props.state.selectedProfile.photo !== "null"
                      ? <img alt="Profile Avatar" onLoad={hideImageLoader} style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: "50%" }} src={props.state.selectedProfile.photo} />
                      : <img alt="Profile Avatar" onLoad={hideImageLoader} style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: "50%" }} src="/images/tappy-avatar.png" />
                    }
                    {props.state.selectedProfile &&
                      <UploadPhoto
                        hideImageLoader={hideImageLoader}
                        showImageLoader={showImageLoader}
                        saveProfilePhoto={saveProfilePhoto}
                        userId={props.state.selectedProfile.user_id} />
                    }
                  </div>
                  <div className="photo-name-upload">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="name-lable">Your name</Form.Label>
                      <Form.Control
                        type="text"
                        value={fullName}
                        onChange={(event) => {
                          setFullName(event.target.value);
                        }} />

                    </Form.Group>
                  </div>

                </div>
                {error.active && (
                  <Error error={error} />
                )}
                <Form.Group className="edit-profile-bio__wrapper" controlId="formBasicEmail">
                  <Form.Label>Bio <span className="lable-span">(150 characters)</span></Form.Label>
                  <Form.Control
                    value={bio}
                    onChange={(event) => {
                      setBio(event.target.value);
                    }}
                    as="textarea"
                    id="foo"
                    rows={4}
                    maxLength={150}
                  />
                </Form.Group>
                <div className="contact-card-section-1">
                  <div className="edit-contact-card__wrapper">
                    <span className="contact-card-lable">
                      Contact Card
                    </span>

                    <div className="toggle-container" style={{ display: "flex", marginTop: "2px" }}>
                      {props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.public
                        ? <span className="active"> enabled </span>
                        : <span className="deactive" style={{ width: "50px", textAlign: "center", color: "red", marginRight: 5 }}> disabled </span>
                      }
                      <div className="togglePrivacy">
                        <input type="checkbox" id="togglePrivacy" className="checkbox"
                          checked={props.state.selectedProfile && props.state.selectedProfile.contactCard && props.state.selectedProfile.contactCard.public ? true : false}
                          onChange={() => handleTogglePrivacy()}
                        />
                        <label htmlFor="togglePrivacy" className="switch" style={{ margin: "0" }}></label>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span className="contact-card-text-wrapper">
                      Add your phone number, email, and addresses here.
                      Your contact card can be securely saved into the contacts app.
                    </span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                    <Button
                      className="edit-profile-edit-contact-card__btn"
                      onClick={(event) => {
                        event.preventDefault();
                        setToggleEditContactCard(true);
                      }}>
                      Edit Contact Card
                    </Button>
                  </div>
                </div>

                <Form.Group className="add-profile-wrapper">
                  <div className="add-profile-prompt">
                    <Dropdown drop='up' className="socials-select">
                      <Dropdown.Toggle id="dropdown-basic">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 59" fill="none">
                          <path d="M30.8798 0.422363C14.0769 0.422363 0.452637 13.4773 0.452637 29.5779C0.452637 45.6786 14.0769 58.7335 30.8798 58.7335C47.6827 58.7335 61.307 45.6786 61.307 29.5779C61.307 13.4773 47.6827 0.422363 30.8798 0.422363ZM43.92 31.1398C43.92 31.4262 43.6755 31.6605 43.3767 31.6605H33.0532V41.5525C33.0532 41.8389 32.8087 42.0732 32.5098 42.0732H29.2498C28.9509 42.0732 28.7064 41.8389 28.7064 41.5525V31.6605H18.3829C18.0841 31.6605 17.8396 31.4262 17.8396 31.1398V28.016C17.8396 27.7297 18.0841 27.4954 18.3829 27.4954H28.7064V17.6033C28.7064 17.317 28.9509 17.0827 29.2498 17.0827H32.5098C32.8087 17.0827 33.0532 17.317 33.0532 17.6033V27.4954H43.3767C43.6755 27.4954 43.92 27.7297 43.92 28.016V31.1398Z" fill="#ffffff" />
                        </svg> */}
                        <AiFillPlusCircle size={63} color="white" />
                        <span className="add-block-dropdown">Add a block</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {props.state.socials && props.state.socials.map((social: Social, index: number) => {
                          return (
                            <Dropdown.Item style={{ width: "100%" }} key={index} onClick={() => onAddSocial(social.name, social.id)}>{social.name}</Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="social-rearrange-text">
                    tap and drag icons to rearrange the order
                  </div>
                  <div className="edit-social-media-profiles">
                    {socialsState && socialsState.length > 0 && <EditSocial
                      cursorProp={cursorProp}
                      setCursorProp={setCursorProp}
                      socials={socialsState}
                      onHide={onRemoveSocial}
                      updateSocialState={updateSocialState}
                      updateWebsiteName={updateWebsiteName}
                      setSocialState={setSocialState}
                    />}
                  </div>
                </Form.Group>
              </Tab>
              <Tab eventKey="design" title="Design" className="edit-profile-tab">
                <Form.Group className="contact-card-section" controlId="editContactCard">
                  <EditTheme state={props.state} setState={props.setState} />
                </Form.Group>
              </Tab>
              <Tab eventKey="settings" title="Branding" className="edit-profile-tab">
                <ProfileSettings />
              </Tab>
            </Tabs>
            <div>
              <Button
                className="edit-profile-edit-contact-card__btn profile__btn"
                onClick={(event) => {
                  event.preventDefault();
                  handleSaveProfile();
                }}
              >Update Profile</Button>
            </div>

          </Form>
        </Container>}
      {animationLoader || animLoader ? <LottieLoading /> : <></>}

      {toggleEditContactCard && <EditContactCard onHideModal={() => setToggleEditContactCard(false)} showModal={toggleEditContactCard} state={props.state} />}
    </div>
  )
}