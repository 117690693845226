import "./EditSocial.scss";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getSocialIcon } from "../../utils/global/global";

interface Props {
  cursorProp: any;
  setCursorProp: React.Dispatch<React.SetStateAction<any>>;
  socials: any[];
  onHide: (key: number) => void;
  setSocialState: (value: any) => void;
  updateSocialState: (index: number, value: string) => void;
  updateWebsiteName: (index: number, value: string) => void;
}

export const EditSocial: React.FC<Props> = (props) => {
  //console.log(props.socials);

  const editSocialInput = (index: Number) => {
    const socialContainer = document.getElementById(`social-container-${index}`);
    socialContainer?.focus();
  }
  
  const editSocialUrl = (index: Number) => {
    const socialContainer = document.getElementById(`social-container1-${index}`);
    socialContainer?.focus();
  }

  const getSocialPlaceholder = (socialTitle: any) => {
    switch (socialTitle) {
      case "Twitter":
        return "Username";
      case "Apple Music":
        return "Username";
      case "Cashapp":
        return "Username";
      case "Email":
        return "example@example.com";
      case "Facebook":
        return "Username";
      case "Instagram":
        return "Username";
      case "Linkedin":
        return "Username";
      case "Paypal":
        return "Username";
      case "Phone":
        return "1-234-567-8900";
      case "Snapchat":
        return "Username";
      case "Soundcloud":
        return "www.soundcloud.com/example";
      case "Spotify Account":
        return "Username";
      case "Spotify Playlist":
        return "Playlist Name";
      case "Tiktok":
        return "Username";
      case "Twitch":
        return "Username";
      case "Venmo":
        return "Username";
      case "Youtube":
        return "Channel Name";
      case "Youtube Video":
        return "Video Name";
      case "Website":
        return "Website Title";
      default:
        return "URL following https://";
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    props.setSocialState(result);
    return result;
  };

  const grid = 4;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    display: "flex",
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    margin: `0 0 14px 0`,
    position: "relative",

    // change background colour if dragging
    background: isDragging ? "rgba(255,255,255,0.2)" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    border: isDraggingOver ? "2px dashed grey" : "1px solid transparent",
    background: "transparent",
    padding: grid,
    width: "100%"
  });

  const [items, setItems] = useState(props.socials);

  useEffect(() => {
    var orderedSocials = [];
    for (var i = 0; i < props.socials.length; i++) {
      orderedSocials.push(props.socials[props.socials.indexOf(i)])
    }
    setItems(props.socials);
  }, [props.socials])


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.length > 0 && items.map((item, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className="edit-social-input-container">
                      <div className="edit-social-input__wrapper">
                        <div className="edit-social-icon__wrapper">
                          <img className="edit-social-icon" src={getSocialIcon(item.name.toString())} alt="social-icon" />
                        </div>
                        <div className="edit-social-input-field-container" onClick={(e) => editSocialInput(index)}>
                          <Form.Control
                            id={`social-container-${index}`}
                            type="text"
                            className="url-container"
                            placeholder={getSocialPlaceholder(item.name.toString())}
                            value={item.pivot ? item.pivot.username : ''}
                            onChange={(event: any) => {
                              let res = [...items];
                              if (item.name === 'Youtube') {
                                items[index].pivot.username = event.target.value.trim()
                              } else {
                                items[index].pivot.username = event.target.value;
                              }
                              setItems(res);
                            }}
                            onBlur={(event: any) => {
                              if (items[index].name === "Twitter" ||
                                items[index].name === "Twitch" ||
                                items[index].name === "Snapchat" ||
                                items[index].name === "Facebook") {
                                props.updateSocialState(index, event.target.value.split("/").pop());
                              } else if (items[index].name === "Instagram") {
                                const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
                                const instaUsername = regexp.test(event.target.value) ? event?.target?.value?.split("/").slice(0, -1).pop() : event.target.value.split("/").pop();
                                props.updateSocialState(index, instaUsername);
                              } else {
                                props.updateSocialState(index, event.target.value);
                              }
                            }}
                          />
                          <div className="edit-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                              <path d="M17.2266 6.42877L12.735 2.05616L14.2146 0.59863C14.6197 0.199543 15.1175 0 15.7079 0C16.2976 0 16.795 0.199543 17.2002 0.59863L18.6797 2.05616C19.0849 2.45525 19.2962 2.93693 19.3138 3.50121C19.3315 4.06479 19.1377 4.54612 18.7326 4.94521L17.2266 6.42877ZM15.6942 7.96438L4.49159 19H0V14.5753L11.2026 3.53973L15.6942 7.96438Z" fill="#848484" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {item.name.toString().toLowerCase() === "website" || item.name.toString().toLowerCase() === "youtube video" || item.name.toString().toLowerCase() === "spotify playlist" ?
                        (
                          <div className="edit-social-input__wrapper edit-social-input-url-container" onClick={(e) => editSocialUrl(index)}>
                            <div className="edit-social-input-field-container ">
                              <Form.Control
                                type="text"
                                id={`social-container1-${index}`}
                                placeholder="URL following https://"
                                className="url-container"
                                value={item?.pivot?.website_url ||item?.url || ''}
                                onChange={(event: any) => {
                                  let res = [...items];
                                  items[index].url = event.target.value;
                                  items[index].pivot.website_url = event.target.value;
                                  setItems(res);
                                }}
                                onBlur={(event: any) => {
                                  props.updateWebsiteName(index, event.target.value);
                                }}
                              />
                            </div>
                            <div className="edit-button url-edit-button">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                <path d="M17.2266 6.42877L12.735 2.05616L14.2146 0.59863C14.6197 0.199543 15.1175 0 15.7079 0C16.2976 0 16.795 0.199543 17.2002 0.59863L18.6797 2.05616C19.0849 2.45525 19.2962 2.93693 19.3138 3.50121C19.3315 4.06479 19.1377 4.54612 18.7326 4.94521L17.2266 6.42877ZM15.6942 7.96438L4.49159 19H0V14.5753L11.2026 3.53973L15.6942 7.96438Z" fill="#848484" />
                              </svg>
                            </div>
                          </div>
                        )
                        : item.name.toString().toLowerCase() === "youtube" ?
                          (
                            <div className="edit-social-input__wrapper edit-social-input-url-container" onClick={(e) => editSocialUrl(index)} >
                              <div className="edit-social-input-field-container">
                                <Form.Control
                                  type="text"
                                  id={`social-container1-${index}`}
                                  className="url-container"
                                  disabled={true}
                                  placeholder="/channel/UAsOPD3PnnvaS020ULWrKdIQ"
                                  value={item.url ? item.url + '/' + item?.pivot?.username : ''}
                                  onChange={(event: any) => {
                                    let res = [...items];
                                    items[index].pivot.website_url = event.target.value;
                                    setItems(res);
                                  }}
                                  onBlur={(event: any) => {
                                    props.updateWebsiteName(index, event.target.value.split("/").pop());
                                  }}
                                />
                              </div>
                              {/* <div className="edit-button url-edit-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                  <path d="M17.2266 6.42877L12.735 2.05616L14.2146 0.59863C14.6197 0.199543 15.1175 0 15.7079 0C16.2976 0 16.795 0.199543 17.2002 0.59863L18.6797 2.05616C19.0849 2.45525 19.2962 2.93693 19.3138 3.50121C19.3315 4.06479 19.1377 4.54612 18.7326 4.94521L17.2266 6.42877ZM15.6942 7.96438L4.49159 19H0V14.5753L11.2026 3.53973L15.6942 7.96438Z" fill="#848484" />
                                </svg>
                              </div> */}
                            </div>
                          ) : null
                      }
                    </div>
                    <div
                      className="edit-social-delete__wrapper"
                      onClick={() => props.onHide(index)}>
                      <span style={{ marginBottom: "5px" }}>&#215;</span>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}