import Button from "react-bootstrap/Button";
import React from "react";
import {ButtonVariant} from "react-bootstrap/types";
import {AppContextInterface, useAppData} from "../hooks/useAppData";

declare type ButtonType = 'button' | 'reset' | 'submit' | string;

interface Props {
    children?: any;
    onClick?: (event: any) => void;
    className?: string;
    style?: any,
    href?: string;
    disabled?: boolean;
    target?: any;
    active?: boolean;
    block?: boolean;
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
    type?: ButtonType;
    [key: string]: any;
}

const CustomButton: React.FC<Props> = ({
    children,
    onClick,
    style,
    className,
    ...props

}) => {
    const { accentColor } = useAppData() as AppContextInterface;
    let csStyle: any = {
        backgroundColor: accentColor,
    }
    if(props.danger || props.variant === 'danger'){
        csStyle = {}
    }

    return (
        <Button
            {...props}
            onClick={onClick}
            style={{
                ...csStyle,
                ...style

            }}
            className={className}

        >
            {children}
        </Button>
    );
}

export default CustomButton;