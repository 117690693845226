import "./Login.scss";
import React, { useState, useEffect } from "react";
import { Form, Container, InputGroup } from "react-bootstrap";
import { SignInButton } from "../SignInButton/SignInButton";
import { RegisterButton } from "../RegisterButton/RegisterButton";
import { Link } from "react-router-dom";
import axiosApi, { setAuthHeader } from "../../axiosApi";
import { Error } from "../Error/Error";
import { googleAnalyticsCustomEvent, googleAnalyticsEvent } from "../../utils/google-analytics";
import { getCurrentDateTime } from "../../utils/global/global";

interface Props {
  checkUserSession: () => void;
  handleHistory: (url: string) => void;
  setLoading: (boolean: boolean) => void;
}

export const Login: React.FC<Props> = (props) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState({
    active: false,
    message: ""
  })

  /**
   * Keyboard on enter key pressed event
   * Only one per page recommended
   */
  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void; }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //console.log("Enter key was pressed.");
        event.preventDefault();
        document.getElementById('signInBtn')?.click();
        //because calling handleLogin causes 422 error
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const togglePassword = (e: any) => {
    const passwordField = e.target.parentElement.parentElement.firstChild;
    setHidePassword(!hidePassword);
    passwordField.type = hidePassword ? "text" : "password";
  }

  const handleLogin = async () => {
    props.setLoading(true);
    const data = {
      email: username,
      password
    }

    try {
      const response = await axiosApi.post(`user/login`, data);
      if (response.data.access_token) {

        const event = {
          action: 'user',
          value: username,
          key: 'log_in'
        }
        googleAnalyticsCustomEvent(event)

        const lastDate = getCurrentDateTime()
        localStorage.setItem('lastTime', lastDate);
        localStorage.setItem('accessToken', response.data.access_token);
        setAuthHeader(response.data.access_token)
        props.checkUserSession();
      } else {
        props.setLoading(false);
        setError({
          active: true,
          message: response.data.error
        })
      }
    } catch (err) {
      props.setLoading(false);
    }
    //console.log(response);
  }

  return (
    <Container className="login__wrapper">
      <span className="login__welcome-back">Welcome back 👋</span>
      {error.active && (
        <Error error={error} />
      )}
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="username / email"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <InputGroup>
            <Form.Control
              type="password"
              placeholder="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }} />
            <InputGroup.Append onClick={(event: any) => { togglePassword(event) }}>
              {hidePassword ? <img src="/images/eye-off.svg" alt="passwordHidden" /> : <img src="/images/eye-on.svg" alt="passwordShown" />}
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <div className="login-call-to-action__wrapper">
          <SignInButton
            text="Sign in"
            onclick={handleLogin}
            handleHistory={props.handleHistory} />
          <Link to="/resetPassword">
            <span className="forgot-password">Forgot password?</span>
          </Link>
        </div>

      </Form>
      <div className="no-account__wrapper">
        <span className="no-account">no account yet?</span>
        <RegisterButton text="Create an account" />
      </div>
    </Container>
  );
}

